import React, { useMemo, useState } from 'react';
import styles from './ParentFreeAttendances.module.scss';
import { connect } from 'react-redux';
import { User } from '../../../../domain/User';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import { Popover } from '@mui/material';
import Label from '../../../Label/Label';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Props = {
  currentUser: User | null;
};

const ParentFreeAttendances = ({ currentUser }: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const intl = useIntl();

  const totalCount = useMemo(
    () =>
      currentUser?.parent?.children?.reduce(
        (sum, child) => (sum += child.freeAttendancesCount),
        0,
      ) || 0,
    [currentUser],
  );

  if (!currentUser?.parent?.children?.length || !totalCount) {
    return null;
  }

  return (
    <>
      <Label
        variant="info"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setIsPopoverOpen(true);
        }}
      >
        {translate(intl, 'PARENT.FREE_ATTENDANCE_COUNT').replace(
          ':count',
          totalCount.toString(),
        )}
      </Label>
      <Popover
        className={styles.popover}
        open={isPopoverOpen}
        onClose={() => setIsPopoverOpen(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className={styles.popoverContent}>
          {currentUser?.parent?.children
            .filter((child) => child.freeAttendancesCount)
            ?.map((child) => (
              <div key={child.id} className={styles.freeAttendanceCard}>
                <div className={styles.freeAttendanceIcon}>
                  <FontAwesomeIcon
                    icon={faCalendar as IconProp}
                    fixedWidth
                    size="sm"
                  />
                </div>
                <div className={styles.freeAttendanceText}>
                  <div className={styles.freeAttendanceTitle}>
                    {translate(intl, 'PARENT.CHILD_FREE_ATTENDANCE').replace(
                      ':count',
                      (child.freeAttendancesCount || 0).toString(),
                    )}
                  </div>
                  <div
                    className={styles.freeAttendanceSubTitle}
                  >{`${child.firstName} ${child.lastName}`}</div>
                </div>
              </div>
            ))}
        </div>
      </Popover>
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(ParentFreeAttendances);
