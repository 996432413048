export const FETCH_OFF_DAY_START = 'FETCH_OFF_DAY_START';
export const FETCH_OFF_DAY_SUCCESS = 'FETCH_OFF_DAY_SUCCESS';
export const FETCH_OFF_DAY_FAIL = 'FETCH_OFF_DAY_FAIL';

export const CREATE_OFF_DAY_START = 'CREATE_OFF_DAY_START';
export const CREATE_OFF_DAY_SUCCESS = 'CREATE_OFF_DAY_SUCCESS';
export const CREATE_OFF_DAY_FAIL = 'CREATE_OFF_DAY_FAIL';

export const UPDATE_OFF_DAY_START = 'UPDATE_OFF_DAY_START';
export const UPDATE_OFF_DAY_SUCCESS = 'UPDATE_OFF_DAY_SUCCESS';
export const UPDATE_OFF_DAY_FAIL = 'UPDATE_OFF_DAY_FAIL';

export const DELETE_OFF_DAY_START = 'DELETE_OFF_DAY_START';
export const DELETE_OFF_DAY_SUCCESS = 'DELETE_OFF_DAY_SUCCESS';
export const DELETE_OFF_DAY_FAIL = 'DELETE_OFF_DAY_FAIL';

export const RESET_OFF_DAY_STORE = 'RESET_OFF_DAY_STORE';
export const RESET_PARTIALLY_OFF_DAY_STORE = 'RESET_PARTIALLY_OFF_DAY_STORE';

export const LOGOUT = 'LOGOUT';

export type OffDayActionTypes =
  | typeof FETCH_OFF_DAY_START
  | typeof FETCH_OFF_DAY_SUCCESS
  | typeof FETCH_OFF_DAY_FAIL
  | typeof CREATE_OFF_DAY_START
  | typeof CREATE_OFF_DAY_SUCCESS
  | typeof CREATE_OFF_DAY_FAIL
  | typeof UPDATE_OFF_DAY_START
  | typeof UPDATE_OFF_DAY_SUCCESS
  | typeof UPDATE_OFF_DAY_FAIL
  | typeof DELETE_OFF_DAY_START
  | typeof DELETE_OFF_DAY_SUCCESS
  | typeof DELETE_OFF_DAY_FAIL
  | typeof RESET_OFF_DAY_STORE
  | typeof RESET_PARTIALLY_OFF_DAY_STORE
  | typeof LOGOUT;
