import * as actionTypes from './actionTypes';
import { Locale } from '../../domain/Translation';
import { HttpError } from '../../config/Axios/axios-instance';
import { User } from '../../domain/User';

export const loginGoogleStart = () => ({
  type: actionTypes.LOGIN_GOOGLE_START,
});

export const loginGoogleSuccess = (jwtToken: string) => ({
  type: actionTypes.LOGIN_GOOGLE_SUCCESS,
  jwtToken,
});

export const loginGoogleFail = (loginGoogleError: HttpError) => ({
  type: actionTypes.LOGIN_GOOGLE_FAIL,
  loginGoogleError,
});

export const refreshTokenStart = () => ({
  type: actionTypes.REFRESH_TOKEN_START,
});

export const refreshTokenSuccess = (jwtToken: string) => ({
  type: actionTypes.REFRESH_TOKEN_SUCCESS,
  jwtToken,
});

export const refreshTokenFail = (refreshTokenError: HttpError) => ({
  type: actionTypes.REFRESH_TOKEN_FAIL,
  refreshTokenError,
});

export const selectLocale = (selectedLocale: Locale) => ({
  type: actionTypes.SELECT_LOCALE,
  selectedLocale,
});

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginSuccess = (jwtToken: string) => ({
  type: actionTypes.LOGIN_SUCCESS,
  jwtToken,
});

export const loginFail = (loginError: HttpError) => ({
  type: actionTypes.LOGIN_FAIL,
  loginError,
});

export const registrationStart = () => ({
  type: actionTypes.REGISTRATION_START,
});

export const registrationSuccess = () => ({
  type: actionTypes.REGISTRATION_SUCCESS,
});

export const registrationFail = (registrationError: HttpError) => ({
  type: actionTypes.REGISTRATION_FAIL,
  registrationError,
});

export const registerConfirmStart = () => ({
  type: actionTypes.REGISTER_CONFIRM_START,
});

export const registerConfirmSuccess = (jwtToken: string) => ({
  type: actionTypes.REGISTER_CONFIRM_SUCCESS,
  jwtToken,
});

export const registerConfirmFail = (registerConfirmError: HttpError) => ({
  type: actionTypes.REGISTER_CONFIRM_FAIL,
  registerConfirmError,
});

export const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START,
});

export const resetPasswordSuccess = (jwtToken: string) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  jwtToken,
});

export const resetPasswordFail = (resetPasswordError: HttpError) => ({
  type: actionTypes.RESET_PASSWORD_FAIL,
  resetPasswordError,
});

export const validateCodeStart = () => ({
  type: actionTypes.VALIDATE_CODE_START,
});

export const validateCodeSuccess = (validatedUser: User) => ({
  type: actionTypes.VALIDATE_CODE_SUCCESS,
  validatedUser,
});

export const validateCodeFail = (validateCodeError: HttpError) => ({
  type: actionTypes.VALIDATE_CODE_FAIL,
  validateCodeError,
});

export const remindStart = () => ({
  type: actionTypes.REMIND_START,
});

export const remindSuccess = () => ({
  type: actionTypes.REMIND_SUCCESS,
});

export const remindFail = (remindError: HttpError) => ({
  type: actionTypes.REMIND_FAIL,
  remindError,
});

export const setPostLoginUrl = (postLoginUrl: string | null) => ({
  type: actionTypes.SET_POST_LOGIN_URL,
  postLoginUrl,
});

export const resetRegistration = () => ({
  type: actionTypes.RESET_REGISTRATION,
});

export const resetRemindPassword = () => ({
  type: actionTypes.RESET_REMIND_PASSWORD,
});

export const resetLogin = () => ({
  type: actionTypes.RESET_LOGIN,
});

export const logout = () => {
  localStorage.removeItem('token');
  return {
    type: actionTypes.LOGOUT,
  };
};
