import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchBranchesFail,
  fetchBranchesStart,
  fetchBranchesSuccess,
  fetchBranchFail,
  fetchBranchStart,
  fetchBranchSuccess,
  updateBranchFail,
  updateBranchStart,
  updateBranchSuccess,
  fetchBranchOptionsStart,
  fetchBranchOptionsSuccess,
  fetchBranchOptionsFail,
  selectBranch,
  fetchAllBranchesStart,
  fetchAllBranchesSuccess,
  fetchAllBranchesFail,
} from './actions';
import { getFormData } from '../../utility/form/formData';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { refreshWorkTimeStore } from '../work-time/actions';

const API_URL = '/branches';

export type BranchUpdateRequest = {
  id: number;
  name: string;
  description: string;
  image: File;
  managerIds: number[];
};

export const fetchBranches = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchBranchesStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchBranchesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchBranchesFail(err?.response?.data?.message));
    });
};

export const fetchBranch = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchBranchStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchBranchSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchBranchFail(err?.response?.data?.message));
    });
};

export const fetchBranchOptions = () => (dispatch: Dispatch) => {
  dispatch(fetchBranchOptionsStart());
  return axios
    .get(`${API_URL}/available`)
    .then((response) => {
      dispatch(fetchBranchOptionsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchBranchOptionsFail(err?.response?.data?.message));
    });
};

export const fetchAllBranches = () => (dispatch: Dispatch) => {
  dispatch(fetchAllBranchesStart());
  return axios
    .get(`${API_URL}/all`)
    .then((response) => {
      dispatch(fetchAllBranchesSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllBranchesFail(err?.response?.data?.message));
    });
};

export const updateBranch =
  (inputs: BranchUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateBranchStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, getFormData(inputs))
      .then((response) => {
        dispatch(updateBranchSuccess());
        showToast(translate(intl, 'SUCCESS.BRANCH_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateBranchFail(err?.response?.data?.message));
      });
  };

export const selectNewBranch = (branchId: number) => (dispatch: Dispatch) => {
  dispatch(selectBranch(branchId));
  dispatch(refreshWorkTimeStore());
};
