import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Branch } from '../../domain/Branch';
import { ListResults } from '../../common/List/List';

export const fetchBranchesStart = () => ({
  type: actionTypes.FETCH_BRANCHES_START,
});

export const fetchBranchesSuccess = (branchesList: ListResults<Branch>) => ({
  type: actionTypes.FETCH_BRANCHES_SUCCESS,
  branchesList,
});

export const fetchBranchesFail = (branchesError: HttpError) => ({
  type: actionTypes.FETCH_BRANCHES_FAIL,
  branchesError,
});

export const fetchBranchStart = () => ({
  type: actionTypes.FETCH_BRANCH_START,
});

export const fetchBranchSuccess = (branch: Branch) => ({
  type: actionTypes.FETCH_BRANCH_SUCCESS,
  branch,
});

export const fetchBranchFail = (branchError: HttpError) => ({
  type: actionTypes.FETCH_BRANCH_FAIL,
  branchError,
});

export const fetchBranchOptionsStart = () => ({
  type: actionTypes.FETCH_BRANCH_OPTIONS_START,
});

export const fetchBranchOptionsSuccess = (branchOptions: Branch[]) => ({
  type: actionTypes.FETCH_BRANCH_OPTIONS_SUCCESS,
  branchOptions,
});

export const fetchBranchOptionsFail = (branchOptionsError: HttpError) => ({
  type: actionTypes.FETCH_BRANCH_OPTIONS_FAIL,
  branchOptionsError,
});

export const fetchAllBranchesStart = () => ({
  type: actionTypes.FETCH_BRANCH_ALL_START,
});

export const fetchAllBranchesSuccess = (allBranches: Branch[]) => ({
  type: actionTypes.FETCH_BRANCH_ALL_SUCCESS,
  allBranches,
});

export const fetchAllBranchesFail = (allBranchesError: HttpError) => ({
  type: actionTypes.FETCH_BRANCH_ALL_FAIL,
  allBranchesError,
});

export const updateBranchStart = () => ({
  type: actionTypes.UPDATE_BRANCH_START,
});

export const updateBranchSuccess = () => ({
  type: actionTypes.UPDATE_BRANCH_SUCCESS,
});

export const updateBranchFail = (branchUpdateError: HttpError) => ({
  type: actionTypes.UPDATE_BRANCH_FAIL,
  branchUpdateError,
});

export const selectBranch = (selectedBranch: number) => ({
  type: actionTypes.SELECT_BRANCH,
  selectedBranch,
});

export const resetBranchStore = () => ({
  type: actionTypes.RESET_BRANCH_STORE,
});
