export const FETCH_CHILD_ATTENDANCES_START = 'FETCH_CHILD_ATTENDANCES_START';
export const FETCH_CHILD_ATTENDANCES_SUCCESS =
  'FETCH_CHILD_ATTENDANCES_SUCCESS';
export const FETCH_CHILD_ATTENDANCES_FAIL = 'FETCH_CHILD_ATTENDANCES_FAIL';

export const FETCH_PARENT_ATTENDANCES_START = 'FETCH_PARENT_ATTENDANCES_START';
export const FETCH_PARENT_ATTENDANCES_SUCCESS =
  'FETCH_PARENT_ATTENDANCES_SUCCESS';
export const FETCH_PARENT_ATTENDANCES_FAIL = 'FETCH_PARENT_ATTENDANCES_FAIL';

export const CREATE_ATTENDANCE_START = 'CREATE_ATTENDANCE_START';
export const CREATE_ATTENDANCE_SUCCESS = 'CREATE_ATTENDANCE_SUCCESS';
export const CREATE_ATTENDANCE_FAIL = 'CREATE_ATTENDANCE_FAIL';

export const TOGGLE_ATTENDANCE_START = 'TOGGLE_ATTENDANCE_START';
export const TOGGLE_ATTENDANCE_SUCCESS = 'TOGGLE_ATTENDANCE_SUCCESS';
export const TOGGLE_ATTENDANCE_FAIL = 'TOGGLE_ATTENDANCE_FAIL';

export const EXPORT_ATTENDANCES_START = 'EXPORT_ATTENDANCES_START';
export const EXPORT_ATTENDANCES_SUCCESS = 'EXPORT_ATTENDANCES_SUCCESS';
export const EXPORT_ATTENDANCES_FAIL = 'EXPORT_ATTENDANCES_FAIL';

export const DELETE_ATTENDANCE_START = 'DELETE_ATTENDANCE_START';
export const DELETE_ATTENDANCE_SUCCESS = 'DELETE_ATTENDANCE_SUCCESS';
export const DELETE_ATTENDANCE_FAIL = 'DELETE_ATTENDANCE_FAIL';

export const PURCHASE_ATTENDANCES_START = 'PURCHASE_ATTENDANCES_START';
export const PURCHASE_ATTENDANCES_SUCCESS = 'PURCHASE_ATTENDANCES_SUCCESS';
export const PURCHASE_ATTENDANCES_FAIL = 'PURCHASE_ATTENDANCES_FAIL';

export const RESET_ATTENDANCE_STORE = 'RESET_ATTENDANCE_STORE';
export const RESET_PURCHASE_ATTENDANCES_STORE =
  'RESET_PURCHASE_ATTENDANCES_STORE';

export const LOGOUT = 'LOGOUT';

export type AttendanceActionTypes =
  | typeof FETCH_CHILD_ATTENDANCES_START
  | typeof FETCH_CHILD_ATTENDANCES_SUCCESS
  | typeof FETCH_CHILD_ATTENDANCES_FAIL
  | typeof FETCH_PARENT_ATTENDANCES_START
  | typeof FETCH_PARENT_ATTENDANCES_SUCCESS
  | typeof FETCH_PARENT_ATTENDANCES_FAIL
  | typeof DELETE_ATTENDANCE_START
  | typeof DELETE_ATTENDANCE_SUCCESS
  | typeof DELETE_ATTENDANCE_FAIL
  | typeof CREATE_ATTENDANCE_START
  | typeof CREATE_ATTENDANCE_SUCCESS
  | typeof CREATE_ATTENDANCE_FAIL
  | typeof TOGGLE_ATTENDANCE_START
  | typeof TOGGLE_ATTENDANCE_SUCCESS
  | typeof TOGGLE_ATTENDANCE_FAIL
  | typeof EXPORT_ATTENDANCES_START
  | typeof EXPORT_ATTENDANCES_SUCCESS
  | typeof EXPORT_ATTENDANCES_FAIL
  | typeof PURCHASE_ATTENDANCES_START
  | typeof PURCHASE_ATTENDANCES_SUCCESS
  | typeof PURCHASE_ATTENDANCES_FAIL
  | typeof RESET_ATTENDANCE_STORE
  | typeof RESET_PURCHASE_ATTENDANCES_STORE
  | typeof LOGOUT;
