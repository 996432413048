import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './BranchSelectForm.module.scss';
import { StoreState } from '../../../../config/StoreProvider/StoreProvider';
import Alert from '../../../../common/Alert/Alert';
import TextField from '../../../../common/TextField/TextField';
import { useForm } from '../../../../hooks/useForm/useForm';
import Button from '../../../../common/Button/Button';
import { HttpError } from '../../../../config/Axios/axios-instance';
import Form from '../../../../common/Form/Form';
import FormCard from '../../../../common/FormCard/FormCard';
import { getGlobalError } from '../../../../utility/error/httpErrorParser';
import { Branch } from '../../../../domain/Branch';
import * as branchService from '../../../../store/branch/service';
import { useIntl } from 'react-intl';
import { translate } from '../../../../utility/messageTranslator/translate';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../../config/Router/routes';

type Props = {
  isLoading?: boolean;
  error: HttpError;
  branchOptions: Branch[];
  onBranchOptionsFetch: () => void;
  onBranchSelect: (branchId: number) => void;
  onSuccessSelect?: () => void;
  selectedBranch: number | null;
};

type FormInputs = {
  branchId: string;
};

export const BranchSelectForm = ({
  isLoading,
  error,
  branchOptions,
  onBranchOptionsFetch,
  onBranchSelect,
  onSuccessSelect,
  selectedBranch,
}: Props) => {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    onBranchOptionsFetch();
  }, []);

  const branchesOptions = useMemo(
    () =>
      branchOptions.map((branch: Branch) => {
        return {
          value: branch.id.toString(),
          label: branch.name,
        };
      }) || [],
    [branchOptions],
  );

  const INPUTS = [
    {
      name: 'branchId',
      label: translate(intl, 'BRANCH.FORM_SELECTED_BRANCH'),
      type: 'autocomplete',
      validation: [
        {
          type: 'required',
        },
      ],
      options: branchesOptions,
      value: selectedBranch?.toString() ?? '',
    },
  ];

  const handleSubmit = (submitInputs: FormInputs) => {
    onBranchSelect(Number(submitInputs.branchId));
    onSuccessSelect?.();

    if (
      location.pathname.includes(routes.workTimes.list) &&
      location.pathname !== routes.workTimes.list
    ) {
      navigate(routes.workTimes.list);
    }
  };

  const {
    inputs,
    onSubmit,
    onInputChange,
    onLoseInputFocus,
    onSelectChange,
    onSetValidationErrors,
    setNewInputObject,
  } = useForm<FormInputs>(INPUTS, handleSubmit);

  useEffect(() => {
    if (error) {
      onSetValidationErrors(error);
    }
  }, [error]);

  useEffect(() => {
    if (branchesOptions) {
      setNewInputObject('branchId', {
        options: branchesOptions,
      });
    }
  }, [branchesOptions]);

  const globalError = getGlobalError(error, intl);

  return (
    <div className={styles.branchSelectForm}>
      <Form className={styles.form} error={error} onSubmit={onSubmit}>
        <FormCard>
          {globalError && (
            <Alert variant="danger" capitalize={false}>
              {globalError}
            </Alert>
          )}
          {inputs.map((input) => (
            <TextField
              key={input.name}
              onChange={onInputChange}
              value={input.value?.toString() ?? ''}
              label={input.label ?? ''}
              errors={input.validationErrors ?? []}
              onBlur={onLoseInputFocus}
              name={input.name}
              type={input.type}
              options={input.options}
              onSelectChange={onSelectChange}
            />
          ))}
          <Button
            isLoadingButton
            onClick={onSubmit}
            buttonVariant="contained"
            color="primary"
            type="submit"
            isLoading={isLoading}
          >
            {translate(intl, 'BRANCH.FORM_SELECT_BRANCH')}
          </Button>
        </FormCard>
      </Form>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  error: state.branch.branchOptionsError,
  isLoading: state.branch.branchOptionsLoading,
  branchOptions: state.branch.branchOptions,
  selectedBranch: state.branch.selectedBranch,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onBranchSelect: (branchId: number) =>
    dispatch(branchService.selectNewBranch(branchId)),
  onBranchOptionsFetch: () => dispatch(branchService.fetchBranchOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BranchSelectForm);
