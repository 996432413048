import * as actionTypes from './actionTypes';
import { HealthCheckActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { HealthCheck } from '../../domain/HealthCheck';
import { ListResults } from '../../common/List/List';

export type HealthCheckStateType = {
  healthCheckCreateLoading: boolean;
  healthCheckCreateError: HttpError;
  healthCheckCreateSuccess: boolean;
  createdHealthCheck: HealthCheck | null;
  healthCheckUpdateLoading: boolean;
  healthCheckUpdateError: HttpError;
  healthCheckUpdateSuccess: boolean;
  healthCheckDeleteLoading: boolean;
  healthCheckDeleteError: HttpError;
  healthCheckDeleteSuccess: boolean;
  healthChecksList: ListResults<HealthCheck> | null;
  healthChecksLoading: boolean;
  healthChecksError: HttpError;
  healthChecksUpdateNeeded: boolean;
};

export type HealthCheckActionType = HealthCheckStateType & {
  type: HealthCheckActionTypes;
};

export const initialState: HealthCheckStateType = {
  healthCheckCreateLoading: false,
  healthCheckCreateError: null,
  healthCheckCreateSuccess: false,
  createdHealthCheck: null,
  healthCheckUpdateLoading: false,
  healthCheckUpdateError: null,
  healthCheckUpdateSuccess: false,
  healthCheckDeleteLoading: false,
  healthCheckDeleteError: null,
  healthCheckDeleteSuccess: false,
  healthChecksUpdateNeeded: false,
  healthChecksList: null,
  healthChecksError: null,
  healthChecksLoading: false,
};

const fetchHealthChecksStart = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...state,
  healthChecksLoading: true,
  healthChecksUpdateNeeded: false,
});

const fetchHealthChecksSuccess = (
  state: HealthCheckStateType,
  action: HealthCheckActionType,
): HealthCheckStateType => ({
  ...state,
  healthChecksLoading: false,
  healthChecksError: null,
  healthChecksList: action.healthChecksList,
});

const fetchHealthChecksFail = (
  state: HealthCheckStateType,
  action: HealthCheckActionType,
): HealthCheckStateType => ({
  ...state,
  healthChecksLoading: false,
  healthChecksError: action.healthChecksError,
});

const createHealthCheckStart = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...state,
  healthCheckCreateLoading: true,
});

const createHealthCheckSuccess = (
  state: HealthCheckStateType,
  action: HealthCheckActionType,
): HealthCheckStateType => ({
  ...state,
  healthCheckCreateLoading: false,
  healthCheckCreateError: null,
  healthCheckCreateSuccess: true,
  createdHealthCheck: action.createdHealthCheck,
  healthChecksUpdateNeeded: true,
});

const createHealthCheckFail = (
  state: HealthCheckStateType,
  action: HealthCheckActionType,
): HealthCheckStateType => ({
  ...state,
  healthCheckCreateLoading: false,
  healthCheckCreateError: action.healthCheckCreateError,
});

const updateHealthCheckStart = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...state,
  healthCheckUpdateLoading: true,
  healthCheckUpdateSuccess: false,
});

const updateHealthCheckSuccess = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...state,
  healthCheckUpdateLoading: false,
  healthCheckUpdateError: null,
  healthCheckUpdateSuccess: true,
  healthChecksUpdateNeeded: true,
});

const updateHealthCheckFail = (
  state: HealthCheckStateType,
  action: HealthCheckActionType,
): HealthCheckStateType => ({
  ...state,
  healthCheckUpdateLoading: false,
  healthCheckUpdateError: action.healthCheckUpdateError,
});

const deleteHealthCheckStart = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...state,
  healthCheckDeleteLoading: true,
});

const deleteHealthCheckSuccess = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...state,
  healthCheckDeleteLoading: false,
  healthCheckDeleteError: null,
  healthCheckDeleteSuccess: true,
  healthChecksUpdateNeeded: true,
});

const deleteHealthCheckFail = (
  state: HealthCheckStateType,
  action: HealthCheckActionType,
): HealthCheckStateType => ({
  ...state,
  healthCheckDeleteLoading: false,
  healthCheckDeleteError: action.healthCheckDeleteError,
});

const resetPartiallyHealthCheckStore = (
  state: HealthCheckStateType,
): HealthCheckStateType => ({
  ...initialState,
  healthChecksList: state.healthChecksList,
});

const resetHealthCheckStore = (): HealthCheckStateType => ({
  ...initialState,
});

const completelyResetHealthCheckStore = (): HealthCheckStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: HealthCheckActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_HEALTH_CHECK_START:
      return fetchHealthChecksStart(state);
    case actionTypes.FETCH_HEALTH_CHECK_SUCCESS:
      return fetchHealthChecksSuccess(state, action);
    case actionTypes.FETCH_HEALTH_CHECK_FAIL:
      return fetchHealthChecksFail(state, action);
    case actionTypes.CREATE_HEALTH_CHECK_START:
      return createHealthCheckStart(state);
    case actionTypes.CREATE_HEALTH_CHECK_SUCCESS:
      return createHealthCheckSuccess(state, action);
    case actionTypes.CREATE_HEALTH_CHECK_FAIL:
      return createHealthCheckFail(state, action);
    case actionTypes.UPDATE_HEALTH_CHECK_START:
      return updateHealthCheckStart(state);
    case actionTypes.UPDATE_HEALTH_CHECK_SUCCESS:
      return updateHealthCheckSuccess(state);
    case actionTypes.UPDATE_HEALTH_CHECK_FAIL:
      return updateHealthCheckFail(state, action);
    case actionTypes.DELETE_HEALTH_CHECK_START:
      return deleteHealthCheckStart(state);
    case actionTypes.DELETE_HEALTH_CHECK_SUCCESS:
      return deleteHealthCheckSuccess(state);
    case actionTypes.DELETE_HEALTH_CHECK_FAIL:
      return deleteHealthCheckFail(state, action);
    case actionTypes.RESET_PARTIALLY_HEALTH_CHECK_STORE:
      return resetPartiallyHealthCheckStore(state);
    case actionTypes.RESET_HEALTH_CHECK_STORE:
      return resetHealthCheckStore();
    case actionTypes.LOGOUT:
      return completelyResetHealthCheckStore();
    default:
      return state;
  }
};

export default reducer;
