import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  InputLabelProps,
  MenuItem,
  Radio,
  RadioGroup,
  Switch,
  TextField as MuiTextField,
} from '@mui/material';
import styles from './TextField.module.scss';
import cx from 'classnames';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';
import Alert from '../Alert/Alert';
import useDelayedSearch, {
  UseDelayedSearch,
} from '../../hooks/useDelayedSearch/useDelayedSearch';
import { Asset } from '../../domain/Asset';
import Button from '../Button/Button';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import Loader from '../Loader/Loader';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { Editor } from '@tinymce/tinymce-react';
import FileInput from '../FileInput/FileInput';
import { HttpError } from '../../config/Axios/axios-instance';
import * as assetService from '../../store/asset/service';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Locale } from '../../domain/Translation';
import { enGB, lt } from 'date-fns/esm/locale';
import { TimePicker } from '@mui/x-date-pickers';
import SignaturePad from 'react-signature-canvas';
import ClearIcon from '@mui/icons-material/Clear';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import { TypeAnimation } from 'react-type-animation';

type RadioInput = {
  value?: number;
  label?: string;
};

export type RadioProps = {
  value: string;
  radioInputs: RadioInput[];
};

type Props = {
  selectedLocale: Locale;
  onChange?: (event: ChangeEvent<any>) => void;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onCheckboxChange?: (name: string, value: string) => void;
  onInputValueChange?: (name: string, value: string) => void;
  onTimeChange?: (name: string, value: string) => void;
  onBlur?: (event: ChangeEvent<any>) => void;
  onInputBlur?: (name: string) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  value?: string | string[] | File | File[];
  label?: string | ReactNode;
  errors?: Array<string>;
  name: string;
  type?: string;
  placeholder?: string;
  inputProps?: Partial<StandardInputProps>;
  inputLabelProps?: Partial<InputLabelProps>;
  className?: string;
  options?: Array<{
    value: string;
    label: string;
    disabled?: boolean;
    color?: string;
  }>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  variant?: 'filled' | 'outlined' | 'standard';
  isOutlined?: boolean;
  disabled?: boolean;
  helperText?: string;
  clearable?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  onSelectChange?: (value: string | string[], name: string) => void;
  isLoading?: boolean;
  onSearch?: (searchValue: string) => void;
  moreLabel?: string;
  asset?: Asset | null;
  creatable?: boolean;
  onCreate?: () => void;
  autoFocus?: boolean;
  isSmall?: boolean;
  maxFileCount?: number;
  minDate?: string;
  optionAsIcon?: boolean;
  maxDate?: moment.Moment;
  onlyPdf?: boolean;
  allTypes?: boolean;
  radioProps?: RadioProps;
  onRadioChange?: (name: string, value: string) => void;
  hasTypeAnimation?: boolean;
  isAssessmentContainerVisible?: boolean;
};

const TextField = ({
  selectedLocale,
  onChange,
  onBlur,
  value,
  label,
  errors,
  name,
  type,
  placeholder,
  inputProps,
  inputLabelProps,
  className,
  onFileChange,
  onCheckboxChange,
  options,
  labelPlacement,
  onTimeChange,
  disabled,
  helperText,
  clearable,
  multiple,
  disableCloseOnSelect,
  disableClearable,
  onSelectChange,
  isLoading = false,
  onSearch,
  moreLabel,
  asset,
  creatable,
  onCreate,
  autoFocus,
  variant = 'outlined',
  isSmall,
  onInputBlur,
  onInputValueChange,
  maxFileCount,
  onImageDelete,
  onSetValidationErrors,
  minDate,
  optionAsIcon,
  maxDate,
  onlyPdf,
  allTypes,
  isAssessmentContainerVisible,
  hasTypeAnimation,
  radioProps,
  onRadioChange,
  isOutlined,
}: Props) => {
  const [autocompleteInput, setAutocompleteInput] = useState<string>('');

  const intl = useIntl();
  const { width } = useWindowSize();

  const signatureRef = useRef<any>();

  const showErrors = useMemo(() => variant !== 'filled', [variant]);
  const adapterLocale = useMemo(() => {
    if (selectedLocale === 'lt') {
      return lt;
    }

    return enGB;
  }, [selectedLocale]);

  const { setSearchValue }: UseDelayedSearch = useDelayedSearch(
    !!onSearch ? onSearch : () => {},
  );

  useEffect(() => {
    if (type === 'autocomplete') {
      setSearchValue(autocompleteInput);
    }
  }, [autocompleteInput]);

  useEffect(() => {
    if (type === 'richtext') {
      document.addEventListener('focusin', handleRichTextBoxFocusIn);
    }
  }, []);

  useEffect(() => {
    return () =>
      document.removeEventListener('focusin', handleRichTextBoxFocusIn);
  }, []);

  const handleRichTextBoxFocusIn = (event: FocusEvent) => {
    if (
      event.target &&
      // @ts-ignore
      event.target.closest(
        '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root',
      ) !== null
    ) {
      event.stopImmediatePropagation();
    }
  };

  if (type === 'autocomplete') {
    const getSelectionValue = () => {
      if (Array.isArray(value)) {
        return options?.filter((option) =>
          value.toString().includes(option.value),
        );
      }

      if (multiple) {
        const values = value?.toString().split(',');
        return options?.filter(
          (option) =>
            !!values?.find(
              (singleValue: any) =>
                singleValue.toString() === option.value.toString(),
            ),
        );
      }

      return options?.find(
        (option) => option.value.toString() === value?.toString(),
      );
    };

    const getOptions = () => {
      if (!options) {
        return [];
      }

      if (moreLabel) {
        return [...options, { label: moreLabel, value: '' }];
      }

      return options;
    };

    return (
      <MuiAutocomplete
        id={name}
        className={styles.autocomplete}
        multiple={multiple}
        options={getOptions()}
        getOptionLabel={(option: any) => option.label}
        value={getSelectionValue() ?? null}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        disableClearable={disableClearable}
        inputValue={multiple ? autocompleteInput : undefined}
        getOptionDisabled={(option) => option.value === '' || !!option.disabled}
        onChange={(_: any, values: any) => {
          setAutocompleteInput('');
          if (Array.isArray(values)) {
            return onSelectChange?.(
              (values as any[]).map(({ value }) => value),
              name,
            );
          }

          onSelectChange?.(values?.value || '', name);
        }}
        onBlur={onBlur}
        loading={isLoading}
        renderOption={(option) => {
          // @ts-ignore
          const optionKey = option.key;

          const color = options?.find(
            (option) => option.label === optionKey,
          )?.color;

          const colorIcon = color && (
            <div
              className={styles.colorSelect}
              style={{
                backgroundColor: color,
              }}
            ></div>
          );

          return (
            <li {...option}>
              {colorIcon} {optionKey}
            </li>
          );
        }}
        renderInput={(params: any) => (
          <MuiTextField
            {...params}
            label={label}
            placeholder={placeholder}
            helperText={
              showErrors && errors && errors.length > 0 ? errors[0] : ''
            }
            error={errors && errors.length > 0}
            name={name}
            className={cx(styles.textField, styles.textField, className)}
            variant={variant as any}
            size={isSmall ? 'small' : 'medium'}
            onChange={(event) => setAutocompleteInput(event.target.value)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: variant === 'filled' && !errors?.length,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {creatable && (
                    <Button buttonVariant="text" onClick={() => onCreate?.()}>
                      {translate(intl, 'INPUTS.CREATE')}
                    </Button>
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  if (type === 'file') {
    return (
      <FileInput
        value={value as File | File[] | string | string[] | undefined}
        name={name}
        helperText={helperText}
        label={label}
        onFileChange={onFileChange}
        maxFileCount={maxFileCount}
        multiple={multiple}
        errors={errors}
        asset={asset}
        onImageDelete={onImageDelete}
        onSetValidationErrors={onSetValidationErrors}
        onlyPdf={onlyPdf}
        allTypes={allTypes}
      />
    );
  }

  if (type === 'currency') {
    return (
      <>
        {helperText && <Alert variant="warning">{helperText}</Alert>}
        <MuiTextField
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          error={errors && errors.length > 0}
          variant={variant as any}
          name={name}
          helperText={
            showErrors && errors && errors.length > 0 ? errors[0] : ''
          }
          className={cx(
            styles.textField,
            styles.inputWithStartSymbol,
            className,
          )}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
          size={isSmall ? 'small' : 'medium'}
        />
      </>
    );
  }

  if (type === 'time') {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <TimePicker
          ampm={false}
          value={
            typeof value === 'string' ? moment(value, 'HH:mm').toDate() : ''
          }
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue ? moment(newValue?.toString()).format('HH:mm') : '',
              );
          }}
          label={label}
          disabled={disabled}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.timeInput, className)}
              onBlur={onBlur}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'staticdate') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'datetime') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          views={['year', 'month', 'day', 'hours', 'minutes']}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD HH:mm')
                  : '',
              );
          }}
          mask="____-__-__ __:__"
          disabled={disabled}
          inputFormat="yyyy-MM-dd HH:mm"
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          maxDate={maxDate}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'date') {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <DateTimePicker
          views={['year', 'month', 'day']}
          label={label}
          value={value}
          onChange={(newValue: any, keyboardInputValue) => {
            if (!onTimeChange) {
              return;
            }

            if (
              !keyboardInputValue ||
              /^\d{4}-\d{2}-\d{2}$/.test(keyboardInputValue)
            ) {
              onTimeChange(
                name,
                newValue ? moment(newValue).format('YYYY-MM-DD') : '',
              );
            } else {
              onTimeChange(name, 'Invalid date');
            }
          }}
          mask="____-__-__"
          disabled={disabled}
          inputFormat="yyyy-MM-dd"
          minDate={minDate ? new Date(minDate) : undefined}
          maxDate={maxDate ? maxDate.toDate() : undefined}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              placeholder={placeholder}
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'switcher') {
    return (
      <FormControlLabel
        className={cx(styles.switcherContainer, className)}
        labelPlacement={labelPlacement}
        control={
          <Switch
            name={name}
            checked={value === '1'}
            onChange={() =>
              onCheckboxChange &&
              onCheckboxChange(name, value === '0' ? '1' : '0')
            }
          />
        }
        disabled={disabled}
        label={label}
      />
    );
  }

  if (type === 'checkbox') {
    return (
      <FormControlLabel
        className={cx(styles.checkboxContainer, className)}
        labelPlacement={labelPlacement}
        onClick={(e) => e.preventDefault()}
        control={
          <Checkbox
            checked={value === '1'}
            onClick={() =>
              onCheckboxChange &&
              onCheckboxChange(name, value === '0' ? '1' : '0')
            }
          />
        }
        disabled={disabled}
        label={label}
      />
    );
  }

  if (type === 'select') {
    return (
      <MuiTextField
        value={value}
        select
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant={variant as any}
        name={name}
        helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type="number"
        placeholder={placeholder}
        size={isSmall ? 'small' : 'medium'}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) ?? []}
      </MuiTextField>
    );
  }

  if (type === 'richtext') {
    return (
      <div
        className={cx(styles.editorContainer, {
          [styles.error]: errors && errors.length,
        })}
      >
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          disabled={disabled}
          value={value?.toString()}
          onEditorChange={(value) =>
            onInputValueChange && onInputValueChange(name, value)
          }
          onBlur={(event) => {
            event.preventDefault();

            onInputBlur && onInputBlur(name);
          }}
          init={{
            automatic_uploads: true,
            file_picker_types: 'image',
            plugins: 'image',
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor image | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'removeformat | help',
            images_upload_handler: async (blobInfo) => {
              return new Promise(async (resolve, reject) => {
                await assetService.createAsset(
                  blobInfo.blob(),
                  resolve,
                  reject,
                );
              });
            },
          }}
        />
        {errors &&
          errors.map((error, index) => (
            <div className={styles.editorError} key={`editor-error-${index}`}>
              {error}
            </div>
          ))}
      </div>
    );
  }

  if (type === 'signature') {
    const onEndDrawing = () => {
      const signatureData = signatureRef.current.toDataURL();

      onInputValueChange &&
        onInputValueChange(
          name,
          signatureData.replace('data:image/png;base64,', ''),
        );
    };

    const handleClear = () => {
      signatureRef.current?.clear();

      onInputValueChange && onInputValueChange(name, '');
    };

    return (
      <div className={styles.signatureInputContainer}>
        <div
          className={cx(styles.signatureContainer, {
            [styles.error]: errors && errors.length,
          })}
        >
          {label && <span className={styles.label}>{label}</span>}
          <SignaturePad
            ref={signatureRef}
            canvasProps={{
              width: width && width < 400 ? 232 : 300,
              height: 150,
              className: styles.canvas,
            }}
            onEnd={onEndDrawing}
          />
          {clearable && value && (
            <ClearIcon
              className={styles.clearButton}
              onClick={() => handleClear()}
            />
          )}
        </div>

        {errors &&
          errors.map((error, index) => (
            <div
              className={styles.signatureError}
              key={`signature-error-${index}`}
            >
              {error}
            </div>
          ))}
      </div>
    );
  }

  if (type === 'radio' && radioProps && onRadioChange) {
    return (
      <RadioGroup
        value={value}
        onChange={(event) => onRadioChange(name, event.target.value)}
        name={name}
        row
      >
        {radioProps.radioInputs.map((radioInput) => (
          <FormControlLabel
            key={radioInput.value}
            value={radioInput.value}
            labelPlacement="top"
            className={styles.radioInputContainer}
            control={<Radio />}
            label={
              hasTypeAnimation && isAssessmentContainerVisible ? (
                <TypeAnimation
                  sequence={[radioInput.label as string]}
                  speed={65}
                  repeat={1}
                  cursor={false}
                />
              ) : (
                radioInput.label
              )
            }
          />
        ))}
      </RadioGroup>
    );
  }

  return (
    <Loader isLoading={isLoading} size="2xs">
      <MuiTextField
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant={variant as any}
        name={name}
        helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className, {
          [styles.inputWithStartSymbol]: !!inputProps?.startAdornment,
        })}
        type={type}
        placeholder={placeholder}
        InputProps={{
          ...inputProps,
        }}
        InputLabelProps={{ ...inputLabelProps }}
        multiline={type === 'textarea'}
        rows={5}
        disabled={disabled}
        autoFocus={autoFocus}
        size={isSmall ? 'small' : 'medium'}
      />
    </Loader>
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(TextField);
