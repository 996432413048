export const FETCH_TESTING_FORMS_START = 'FETCH_TESTING_FORMS_START';
export const FETCH_TESTING_FORMS_SUCCESS = 'FETCH_TESTING_FORMS_SUCCESS';
export const FETCH_TESTING_FORMS_FAIL = 'FETCH_TESTING_FORMS_FAIL';

export const CREATE_TESTING_FORM_START = 'CREATE_TESTING_FORM_START';
export const CREATE_TESTING_FORM_SUCCESS = 'CREATE_TESTING_FORM_SUCCESS';
export const CREATE_TESTING_FORM_FAIL = 'CREATE_TESTING_FORM_FAIL';

export const UPDATE_TESTING_FORM_START = 'UPDATE_TESTING_FORM_START';
export const UPDATE_TESTING_FORM_SUCCESS = 'UPDATE_TESTING_FORM_SUCCESS';
export const UPDATE_TESTING_FORM_FAIL = 'UPDATE_TESTING_FORM_FAIL';

export const DELETE_TESTING_FORM_START = 'DELETE_TESTING_FORM_START';
export const DELETE_TESTING_FORM_SUCCESS = 'DELETE_TESTING_FORM_SUCCESS';
export const DELETE_TESTING_FORM_FAIL = 'DELETE_TESTING_FORM_FAIL';

export const DOWNLOAD_TESTING_FORM_START = 'DOWNLOAD_TESTING_FORM_START';
export const DOWNLOAD_TESTING_FORM_SUCCESS = 'DOWNLOAD_TESTING_FORM_SUCCESS';
export const DOWNLOAD_TESTING_FORM_FAIL = 'DOWNLOAD_TESTING_FORM_FAIL';

export const SEND_TESTING_FORM_START = 'SEND_TESTING_FORM_START';
export const SEND_TESTING_FORM_SUCCESS = 'SEND_TESTING_FORM_SUCCESS';
export const SEND_TESTING_FORM_FAIL = 'SEND_TESTING_FORM_FAIL';

export const RESET_TESTING_FORM_STORE = 'RESET_TESTING_FORM_STORE';
export const RESET_PARTIALLY_TESTING_FORM_STORE =
  'RESET_PARTIALLY_TESTING_FORM_STORE';

export const LOGOUT = 'LOGOUT';

export type TestingFormActionTypes =
  | typeof FETCH_TESTING_FORMS_START
  | typeof FETCH_TESTING_FORMS_SUCCESS
  | typeof FETCH_TESTING_FORMS_FAIL
  | typeof CREATE_TESTING_FORM_START
  | typeof CREATE_TESTING_FORM_SUCCESS
  | typeof CREATE_TESTING_FORM_FAIL
  | typeof UPDATE_TESTING_FORM_START
  | typeof UPDATE_TESTING_FORM_SUCCESS
  | typeof UPDATE_TESTING_FORM_FAIL
  | typeof DELETE_TESTING_FORM_START
  | typeof DELETE_TESTING_FORM_SUCCESS
  | typeof DELETE_TESTING_FORM_FAIL
  | typeof DOWNLOAD_TESTING_FORM_START
  | typeof DOWNLOAD_TESTING_FORM_SUCCESS
  | typeof DOWNLOAD_TESTING_FORM_FAIL
  | typeof SEND_TESTING_FORM_START
  | typeof SEND_TESTING_FORM_SUCCESS
  | typeof SEND_TESTING_FORM_FAIL
  | typeof RESET_TESTING_FORM_STORE
  | typeof RESET_PARTIALLY_TESTING_FORM_STORE
  | typeof LOGOUT;
