import React, { ReactNode, useEffect, useState } from 'react';
import styles from './ParentLayout.module.scss';
import { NavLink, useLocation } from 'react-router-dom';
import cx from 'classnames';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChildren,
  faCalendar,
  faCalendarTimes,
  faMoneyBill,
  faClock,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import ParentSidebar from './Sidebar/ParentSidebar';
import ParentNavigation from './Navigation/ParentNavigation';
import { routes } from '../../../config/Router/routes';
import useWindowSize from '../../../hooks/useWindowSize/useWindowSize';
import { Roles } from '../../../domain/Role';
import { translate } from '../../../utility/messageTranslator/translate';
import { User } from '../../../domain/User';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';

export type Props = {
  children: ReactNode;
  currentUser: User | null;
};

const MOBILE_BREAK_POINT = 900;

const ParentLayout = ({ children, currentUser }: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const intl = useIntl();

  const { width } = useWindowSize();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setIsMobileMenuOpen(false);
      window.scroll(0, 0);
    }, 100);
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [location.key]);

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_GLOBAL'),
      roles: [Roles.PARENT],
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CHILDREN'),
          to: routes.parent.children.list,
          icon: faChildren as IconProp,
          roles: [Roles.PARENT],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ACHIEVEMENTS'),
          to: routes.parent.achievements,
          icon: faCalendar as IconProp,
          roles: [Roles.PARENT],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.parent.payments,
          icon: faMoneyBill as IconProp,
          roles: [Roles.PARENT],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ATTENDANCES'),
          to: routes.parent.attendances,
          icon: faCalendarTimes as IconProp,
          roles: [Roles.PARENT],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_WANTED_TIMES'),
          to: routes.parent.wantedTimes,
          icon: faClock as IconProp,
          roles: [Roles.PARENT],
        },
      ],
    },
  ];

  return (
    <>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <ParentSidebar
            navigationGroups={SIDEBAR_ITEMS}
            currentUser={currentUser}
          />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <ParentNavigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
            currentUser={currentUser}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {SIDEBAR_ITEMS.map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items
                .filter(
                  (item) =>
                    currentUser && item.roles.includes(currentUser.role),
                )
                .map((item) => (
                  <NavLink
                    key={item.label}
                    to={item.to}
                    className={({ isActive }) =>
                      cx(styles.navigationItem, {
                        [styles.activeSubItem]: isActive,
                      })
                    }
                  >
                    {item.label}
                  </NavLink>
                ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(ParentLayout);
