import _, { trim } from 'lodash';
import prettyBytes from 'pretty-bytes';
import mime from 'mime';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { translate } from '../messageTranslator/translate';
import { EntityTranslation } from '../../domain/EntityTranslation';

const MESSAGE_REQUIRED = 'This field cannot be empty';
const MESSAGE_EMAIL = 'This field must be a valid email address';
const MESSAGE_TIME = 'Invalid time';
const MESSAGE_STRING_AND_NUMBER =
  'This field must contain only numbers and letters';
const MESSAGE_DATE_INVALID = 'This field must be a valid date';
const MESSAGE_TIME_INVALID = 'This field must be a valid time';

export type ValidationRule = {
  type: string;
  value?: string | string[] | number;
  parameter?: any;
};

export const getValidationError = (
  value: string | string[] | number | File | File[] | EntityTranslation[],
  rules: Array<ValidationRule> | undefined,
  intl: IntlShape,
): Array<string> =>
  _.compact(
    _.map(rules, (rule) => {
      const validator = validatorFactory(rule);
      const validationValue =
        validator &&
        validator(
          // @ts-ignore
          value instanceof File ||
            (Array.isArray(value) &&
              (value as File[]).find((val) => val instanceof File))
            ? Array.isArray(value)
              ? (value as File[]).filter((val) => val instanceof File)
              : value
            : value?.toString() ?? '',
          intl,
          rule.parameter,
        );
      if (validationValue) {
        return validationValue;
      }
    }),
  );

const validatorFactory = (rule: ValidationRule) => {
  switch (rule.type) {
    case 'required':
      return requiredValidator;
    case 'minLength':
      return minLengthValidator;
    case 'maxLength':
      return maxLengthValidator;
    case 'email':
      return emailValidator;
    case 'numbersAndStrings':
      return numbersAndStringsValidator;
    case 'min':
      return minValidator;
    case 'max':
      return maxValidator;
    case 'time':
      return timeValidator;
    case 'fileSize':
      return fileSizeValidator;
    case 'fileExtension':
      return fileExtensionValidator;
    case 'isValidDate':
      return isValidDate;
    case 'isValidTime':
      return isValidTime;
    case 'decimalPlaces':
      return decimalPlaces;
    case 'minDate':
      return minDateValidator;
    case 'maxDate':
      return maxDateValidator;
  }
};

const requiredValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value.length === 0
    ? translate(intl, 'VALIDATION.REQUIRED', MESSAGE_REQUIRED)
    : undefined;

const minLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  value && length && value.length < length
    ? translate(
        intl,
        'VALIDATION.MIN_LENGTH',
        `This field must be more than ${length} symbols`,
      ).replace(':value', length.toString())
    : undefined;

const maxLengthValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length && value.length > length
    ? translate(
        intl,
        'VALIDATION.MAX_LENGTH',
        `This field must be less than ${length} symbols`,
      ).replace(':value', length.toString())
    : undefined;

const numbersAndStringsValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^[a-zA-Z0-9]*$/.test(trim(value.toString()))
    ? undefined
    : translate(
        intl,
        'VALIDATION.NUMBERS_AND_STRINGS',
        MESSAGE_STRING_AND_NUMBER,
      );

const emailValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  value === '' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.EMAIL', MESSAGE_EMAIL);

const minValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length !== undefined && +value < length
    ? `${translate(
        intl,
        'VALIDATION.MIN',
        'This field must be more or equal',
      )} ${length}`
    : undefined;

const maxValidator = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined =>
  length !== undefined && +value > length
    ? `${translate(
        intl,
        'VALIDATION.MAX',
        'This field must be less than',
      )} ${length}`
    : undefined;

const timeValidator = (
  value: string | string[],
  intl: IntlShape,
): string | undefined =>
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(trim(value.toString()))
    ? undefined
    : translate(intl, 'VALIDATION.TIME', MESSAGE_TIME);

const minDateValidator = (
  value: string | string[],
  intl: IntlShape,
  minDate: string,
): string | undefined =>
  moment(value).isAfter(moment(minDate))
    ? undefined
    : translate(intl, 'VALIDATION.FORBIDDEN_DATE');

const maxDateValidator = (
  value: string | string[],
  intl: IntlShape,
  minDate: string,
): string | undefined =>
  moment(value).isBefore(moment(minDate))
    ? undefined
    : translate(intl, 'VALIDATION.FORBIDDEN_DATE');

const fileSizeValidator = (
  value: string | string[],
  intl: IntlShape,
  size?: number,
): string | undefined => {
  const valuesToValidate = Array.isArray(value) ? value : [value];

  const errors = [];

  for (const val of valuesToValidate) {
    errors.push(fileSizeValidatorFn(val, intl, size));
  }

  return errors.filter((error) => error)?.[0];
};

const fileSizeValidatorFn = (
  value: string | string[],
  intl: IntlShape,
  size?: number,
): string | undefined => {
  const file = value as unknown as File;

  if (!(file instanceof File)) {
    return undefined;
  }

  return file && size && size > file.size
    ? undefined
    : `${translate(
        intl,
        'VALIDATION.MAX_FILE_SIZE',
        'File cannot be larger than',
      )} ${prettyBytes(size ?? 0)}`;
};

const fileExtensionValidator = (
  value: string | string[],
  intl: IntlShape,
  extensions?: string[],
): string | undefined => {
  const valuesToValidate = Array.isArray(value) ? value : [value];

  const errors = [];

  for (const val of valuesToValidate) {
    errors.push(fileExtensionValidatorFn(val, intl, extensions));
  }

  return errors.filter((error) => error)?.[0];
};

const fileExtensionValidatorFn = (
  value: string | string[],
  intl: IntlShape,
  extensions?: string[],
): string | undefined => {
  const file = value as unknown as File;

  if (!(file instanceof File)) {
    return undefined;
  }

  return value &&
    extensions &&
    extensions.includes(mime.getExtension(file.type) ?? '')
    ? undefined
    : `${translate(
        intl,
        'VALIDATION.FILE_TYPES',
        'File must be one of these types',
      )}: ${extensions?.toString().replace(',', ', ')}`;
};

const isValidDate = (value: string | string[]): string | undefined =>
  value === '' || moment(value).isValid() ? undefined : MESSAGE_DATE_INVALID;

const isValidTime = (value: string | string[]): string | undefined =>
  value === '' || /^([01]\d|2[0-3]):([0-5]\d)$/.test(value as string)
    ? undefined
    : MESSAGE_TIME_INVALID;

const decimalPlaces = (
  value: string | string[],
  intl: IntlShape,
  length?: number,
): string | undefined => {
  if (length === undefined || !value) {
    return undefined;
  }

  const stringValue = value as string;

  if (!length && stringValue.includes('.')) {
    return translate(intl, 'MESSAGE_DECIMAL_PLACES_INVALID').replace(
      ':decimal',
      length.toString(),
    );
  }

  const splitted = stringValue.split('.');

  if (splitted.length === 1) {
    return undefined;
  }

  if (splitted[1].length > length) {
    return translate(intl, 'MESSAGE_DECIMAL_PLACES_INVALID').replace(
      ':decimal',
      length.toString(),
    );
  }

  return undefined;
};
