import React, { ReactNode } from 'react';
import styles from './Button.module.scss';
import { Button as MuiButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import cx from 'classnames';

export type Props = {
  children?: ReactNode;
  className?: string;
  onClick?: (event: any) => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  variant?: 'primary' | 'success' | 'warning' | 'danger';
  buttonVariant?: 'contained' | 'text';
  isLoading?: boolean;
  isLoadingButton?: boolean;
  isConfirmationButton?: boolean;
  isSaveButton?: boolean;
  color?: 'primary' | 'secondary';
  startIcon?: string | ReactNode;
  endIcon?: string | ReactNode;
  isDisabled?: boolean;
  isActive?: boolean;
};

const Button = ({
  children,
  className,
  onClick,
  type,
  isLoading,
  isLoadingButton,
  isConfirmationButton,
  color,
  buttonVariant,
  endIcon,
  startIcon,
  isSaveButton,
  isDisabled,
  isActive,
}: Props) => {
  if (isLoadingButton) {
    return (
      <LoadingButton
        className={cx(styles.button, className)}
        onClick={onClick}
        variant={buttonVariant ?? 'contained'}
        color={color}
        loading={isLoading}
        loadingPosition={endIcon ? 'end' : undefined}
        endIcon={endIcon}
        type={type}
        disabled={isDisabled}
      >
        {children}
      </LoadingButton>
    );
  }

  if (isSaveButton) {
    return (
      <LoadingButton
        className={cx(styles.button, styles.saveButton, className)}
        onClick={onClick}
        variant={buttonVariant ?? 'contained'}
        color={color}
        loading={isLoading}
        loadingPosition={endIcon ? 'end' : undefined}
        endIcon={endIcon}
        type={type}
        disabled={isDisabled}
      >
        {children}
      </LoadingButton>
    );
  }

  if (isConfirmationButton) {
    return (
      <MuiButton
        className={cx(styles.button, styles.simpleButton)}
        onClick={onClick}
        variant={'text'}
        color={color}
        startIcon={startIcon}
        disabled={isLoading || isDisabled}
      >
        {children}
      </MuiButton>
    );
  }

  return (
    <MuiButton
      className={cx(
        styles.button,
        styles.simpleButton,
        {
          [styles.textButton]: buttonVariant === 'text',
          [styles.isActive]: isActive,
          [styles.onlyIcon]: !children,
        },
        className,
      )}
      onClick={onClick}
      variant={buttonVariant ?? 'contained'}
      color={color}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={isDisabled}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  variant: 'primary',
  onClick: () => {},
  isLoading: false,
};

export default Button;
