import * as actionTypes from './actionTypes';
import { AttendancePackageActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { AttendancePackage } from '../../domain/AttendancePackage';
import { ListResults } from '../../common/List/List';
import { WorkTime } from '../../domain/WorkTime';

export type AttendancePackageStateType = {
  attendancePackageCreateLoading: boolean;
  attendancePackageCreateError: HttpError;
  attendancePackageCreateSuccess: boolean;
  createdAttendancePackage: AttendancePackage | null;
  attendancePackageUpdateLoading: boolean;
  attendancePackageUpdateError: HttpError;
  attendancePackageUpdateSuccess: boolean;
  attendancePackageDeleteLoading: boolean;
  attendancePackageDeleteError: HttpError;
  attendancePackageDeleteSuccess: boolean;
  attendancePackagesList: ListResults<AttendancePackage> | null;
  attendancePackagesLoading: boolean;
  attendancePackagesError: HttpError;
  attendancePackagesUpdateNeeded: boolean;
  allAttendancePackages: AttendancePackage[] | null;
  allAttendancePackagesError: HttpError;
  allAttendancePackagesLoading: boolean;
  packageWorkTimes: WorkTime[] | null;
  packageWorkTimesLoading: boolean;
  packageWorkTimesHttpError: HttpError;
};

export type AttendancePackageActionType = AttendancePackageStateType & {
  type: AttendancePackageActionTypes;
};

export const initialState: AttendancePackageStateType = {
  attendancePackageCreateLoading: false,
  attendancePackageCreateError: null,
  attendancePackageCreateSuccess: false,
  createdAttendancePackage: null,
  attendancePackageUpdateLoading: false,
  attendancePackageUpdateError: null,
  attendancePackageUpdateSuccess: false,
  attendancePackageDeleteLoading: false,
  attendancePackageDeleteError: null,
  attendancePackageDeleteSuccess: false,
  attendancePackagesUpdateNeeded: false,
  attendancePackagesList: null,
  attendancePackagesError: null,
  attendancePackagesLoading: false,
  allAttendancePackages: null,
  allAttendancePackagesError: null,
  allAttendancePackagesLoading: false,
  packageWorkTimes: null,
  packageWorkTimesHttpError: null,
  packageWorkTimesLoading: false,
};

const fetchAttendancePackagesStart = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackagesLoading: true,
  attendancePackagesUpdateNeeded: false,
});

const fetchAttendancePackagesSuccess = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackagesLoading: false,
  attendancePackagesError: null,
  attendancePackagesList: action.attendancePackagesList,
});

const fetchAttendancePackagesFail = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackagesLoading: false,
  attendancePackagesError: action.attendancePackagesError,
});

const fetchPackageWorkTimesStart = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  packageWorkTimesLoading: true,
  packageWorkTimes: null,
});

const fetchPackageWorkTimesSuccess = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  packageWorkTimesLoading: false,
  packageWorkTimesHttpError: null,
  packageWorkTimes: action.packageWorkTimes,
});

const fetchPackageWorkTimesFail = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  packageWorkTimesLoading: false,
  packageWorkTimesHttpError: action.packageWorkTimesHttpError,
});

const fetchAllAttendancePackagesStart = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  allAttendancePackagesLoading: true,
  attendancePackagesUpdateNeeded: false,
});

const fetchAllAttendancePackagesSuccess = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  allAttendancePackagesLoading: false,
  allAttendancePackagesError: null,
  allAttendancePackages: action.allAttendancePackages,
});

const fetchAllAttendancePackagesFail = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  allAttendancePackagesLoading: false,
  allAttendancePackagesError: action.allAttendancePackagesError,
});

const createAttendancePackageStart = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageCreateLoading: true,
});

const createAttendancePackageSuccess = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageCreateLoading: false,
  attendancePackageCreateError: null,
  attendancePackageCreateSuccess: true,
  createdAttendancePackage: action.createdAttendancePackage,
  attendancePackagesUpdateNeeded: true,
});

const createAttendancePackageFail = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageCreateLoading: false,
  attendancePackageCreateError: action.attendancePackageCreateError,
});

const updateAttendancePackageStart = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageUpdateLoading: true,
  attendancePackageUpdateSuccess: false,
});

const updateAttendancePackageSuccess = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageUpdateLoading: false,
  attendancePackageUpdateError: null,
  attendancePackageUpdateSuccess: true,
  attendancePackagesUpdateNeeded: true,
});

const updateAttendancePackageFail = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageUpdateLoading: false,
  attendancePackageUpdateError: action.attendancePackageUpdateError,
});

const deleteAttendancePackageStart = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageDeleteLoading: true,
});

const deleteAttendancePackageSuccess = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageDeleteLoading: false,
  attendancePackageDeleteError: null,
  attendancePackageDeleteSuccess: true,
  attendancePackagesUpdateNeeded: true,
});

const deleteAttendancePackageFail = (
  state: AttendancePackageStateType,
  action: AttendancePackageActionType,
): AttendancePackageStateType => ({
  ...state,
  attendancePackageDeleteLoading: false,
  attendancePackageDeleteError: action.attendancePackageDeleteError,
});

const resetPartiallyAttendancePackageStore = (
  state: AttendancePackageStateType,
): AttendancePackageStateType => ({
  ...initialState,
  attendancePackagesList: state.attendancePackagesList,
});

const resetAttendancePackageStore = (): AttendancePackageStateType => ({
  ...initialState,
});

const completelyResetAttendancePackageStore =
  (): AttendancePackageStateType => ({
    ...initialState,
  });

const reducer = (state = initialState, action: AttendancePackageActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ATTENDANCE_PACKAGE_START:
      return fetchAttendancePackagesStart(state);
    case actionTypes.FETCH_ATTENDANCE_PACKAGE_SUCCESS:
      return fetchAttendancePackagesSuccess(state, action);
    case actionTypes.FETCH_ATTENDANCE_PACKAGE_FAIL:
      return fetchAttendancePackagesFail(state, action);
    case actionTypes.FETCH_PACKAGE_WORK_TIMES_START:
      return fetchPackageWorkTimesStart(state);
    case actionTypes.FETCH_PACKAGE_WORK_TIMES_SUCCESS:
      return fetchPackageWorkTimesSuccess(state, action);
    case actionTypes.FETCH_PACKAGE_WORK_TIMES_FAIL:
      return fetchPackageWorkTimesFail(state, action);
    case actionTypes.FETCH_ALL_ATTENDANCE_PACKAGES_START:
      return fetchAllAttendancePackagesStart(state);
    case actionTypes.FETCH_ALL_ATTENDANCE_PACKAGES_SUCCESS:
      return fetchAllAttendancePackagesSuccess(state, action);
    case actionTypes.FETCH_ALL_ATTENDANCE_PACKAGES_FAIL:
      return fetchAllAttendancePackagesFail(state, action);
    case actionTypes.CREATE_ATTENDANCE_PACKAGE_START:
      return createAttendancePackageStart(state);
    case actionTypes.CREATE_ATTENDANCE_PACKAGE_SUCCESS:
      return createAttendancePackageSuccess(state, action);
    case actionTypes.CREATE_ATTENDANCE_PACKAGE_FAIL:
      return createAttendancePackageFail(state, action);
    case actionTypes.UPDATE_ATTENDANCE_PACKAGE_START:
      return updateAttendancePackageStart(state);
    case actionTypes.UPDATE_ATTENDANCE_PACKAGE_SUCCESS:
      return updateAttendancePackageSuccess(state);
    case actionTypes.UPDATE_ATTENDANCE_PACKAGE_FAIL:
      return updateAttendancePackageFail(state, action);
    case actionTypes.DELETE_ATTENDANCE_PACKAGE_START:
      return deleteAttendancePackageStart(state);
    case actionTypes.DELETE_ATTENDANCE_PACKAGE_SUCCESS:
      return deleteAttendancePackageSuccess(state);
    case actionTypes.DELETE_ATTENDANCE_PACKAGE_FAIL:
      return deleteAttendancePackageFail(state, action);
    case actionTypes.RESET_PARTIALLY_ATTENDANCE_PACKAGE_STORE:
      return resetPartiallyAttendancePackageStore(state);
    case actionTypes.RESET_ATTENDANCE_PACKAGE_STORE:
      return resetAttendancePackageStore();
    case actionTypes.LOGOUT:
      return completelyResetAttendancePackageStore();
    default:
      return state;
  }
};

export default reducer;
