import React, { ReactNode } from 'react';
import styles from './AuthLayout.module.scss';
import authBackground from '../../../assets/auth/background.png';
import logo from '../../../assets/logo.png';
import ababaLogo from '../../../assets/ababa-tech-logo.svg';
import { Link } from 'react-router-dom';

type Props = {
  children: ReactNode;
};

export const AuthLayout = ({ children }: Props) => {
  return (
    <div
      className={styles.authLayout}
      style={{ backgroundImage: `url(${authBackground})` }}
    >
      <div className={styles.backdrop}>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={logo} />
          </div>
          <div className={styles.contentInner}>
            <div className={styles.innerCard}>{children}</div>
          </div>
          <Link
            className={styles.ababaLogo}
            to="https://ababa.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ababaLogo} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
