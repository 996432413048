export const FETCH_COMMENTS_START = 'FETCH_COMMENTS_START';
export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export const FETCH_COMMENTS_FAIL = 'FETCH_COMMENTS_FAIL';

export const CREATE_COMMENT_START = 'CREATE_COMMENT_START';
export const CREATE_COMMENT_SUCCESS = 'CREATE_COMMENT_SUCCESS';
export const CREATE_COMMENT_FAIL = 'CREATE_COMMENT_FAIL';

export const UPDATE_COMMENT_START = 'UPDATE_COMMENT_START';
export const UPDATE_COMMENT_SUCCESS = 'UPDATE_COMMENT_SUCCESS';
export const UPDATE_COMMENT_FAIL = 'UPDATE_COMMENT_FAIL';

export const DELETE_COMMENT_START = 'DELETE_COMMENT_START';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export const DELETE_COMMENT_FAIL = 'DELETE_COMMENT_FAIL';

export const RESET_COMMENT_STORE = 'RESET_COMMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type CommentActionTypes =
  | typeof FETCH_COMMENTS_START
  | typeof FETCH_COMMENTS_SUCCESS
  | typeof FETCH_COMMENTS_FAIL
  | typeof CREATE_COMMENT_START
  | typeof CREATE_COMMENT_SUCCESS
  | typeof CREATE_COMMENT_FAIL
  | typeof UPDATE_COMMENT_START
  | typeof UPDATE_COMMENT_SUCCESS
  | typeof UPDATE_COMMENT_FAIL
  | typeof DELETE_COMMENT_START
  | typeof DELETE_COMMENT_SUCCESS
  | typeof DELETE_COMMENT_FAIL
  | typeof RESET_COMMENT_STORE
  | typeof LOGOUT;
