import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  Attendance,
  PurchaseAttendancesResponse,
} from '../../domain/Attendance';
import { AttendanceActionTypes } from './actionTypes';
import { ListResults } from '../../common/List/List';

export type AttendanceStateType = {
  attendanceCreateLoading: boolean;
  attendanceCreateError: HttpError;
  attendanceCreateSuccess: boolean;
  createdAttendance: Attendance | null;
  attendanceToggleLoading: boolean;
  attendanceToggleError: HttpError;
  attendanceToggleSuccess: boolean;
  exportAttendances: Attendance[] | null;
  exportAttendancesError: HttpError;
  exportAttendancesLoading: boolean;
  childAttendances: ListResults<Attendance> | null;
  childAttendancesLoading: boolean;
  childAttendancesError: HttpError;
  parentAttendances: ListResults<Attendance> | null;
  parentAttendancesLoading: boolean;
  parentAttendancesError: HttpError;
  purchaseAttendancesLoading: boolean;
  purchaseAttendancesError: HttpError;
  purchasePaymentData: PurchaseAttendancesResponse | null;
  deleteAttendanceLoading: boolean;
  deleteAttendanceError: HttpError;
  isUpdateNeeded: boolean;
};

export type AttendanceActionType = AttendanceStateType & {
  type: AttendanceActionTypes;
};

export const initialState: AttendanceStateType = {
  attendanceCreateLoading: false,
  attendanceCreateError: null,
  attendanceCreateSuccess: false,
  createdAttendance: null,
  attendanceToggleLoading: false,
  attendanceToggleError: null,
  attendanceToggleSuccess: false,
  exportAttendances: null,
  exportAttendancesError: null,
  exportAttendancesLoading: false,
  childAttendancesError: null,
  childAttendancesLoading: false,
  childAttendances: null,
  purchaseAttendancesError: null,
  purchaseAttendancesLoading: false,
  purchasePaymentData: null,
  parentAttendancesError: null,
  parentAttendances: null,
  parentAttendancesLoading: false,
  deleteAttendanceError: null,
  deleteAttendanceLoading: false,
  isUpdateNeeded: false,
};

const fetchChildAttendancesStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  childAttendancesLoading: true,
  isUpdateNeeded: false,
});

const fetchChildAttendancesSuccess = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  childAttendancesLoading: false,
  childAttendancesError: null,
  childAttendances: action.childAttendances,
});

const fetchChildAttendancesFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  childAttendancesLoading: false,
  childAttendancesError: action.childAttendancesError,
});

const fetchParentAttendancesStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  parentAttendancesLoading: true,
});

const fetchParentAttendancesSuccess = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  parentAttendancesLoading: false,
  parentAttendancesError: null,
  parentAttendances: action.parentAttendances,
});

const fetchParentAttendancesFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  parentAttendancesLoading: false,
  parentAttendancesError: action.parentAttendancesError,
});

const createAttendanceStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  attendanceCreateLoading: true,
});

const createAttendanceSuccess = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  attendanceCreateLoading: false,
  attendanceCreateError: null,
  attendanceCreateSuccess: true,
  createdAttendance: action.createdAttendance,
});

const createAttendanceFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  attendanceCreateLoading: false,
  attendanceCreateError: action.attendanceCreateError,
});

const deleteAttendanceStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  deleteAttendanceLoading: true,
});

const deleteAttendanceSuccess = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  deleteAttendanceLoading: false,
  isUpdateNeeded: true,
});

const deleteAttendanceFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  deleteAttendanceLoading: false,
  deleteAttendanceError: action.deleteAttendanceError,
});

const toggleAttendanceStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  attendanceToggleLoading: true,
  attendanceToggleSuccess: false,
});

const toggleAttendanceSuccess = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  attendanceToggleLoading: false,
  attendanceToggleError: null,
  attendanceToggleSuccess: true,
});

const toggleAttendanceFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  attendanceToggleLoading: false,
  attendanceToggleError: action.attendanceToggleError,
});

const purchaseAttendancesStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  purchaseAttendancesLoading: true,
  purchasePaymentData: null,
  purchaseAttendancesError: null,
});

const purchaseAttendancesSuccess = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  purchaseAttendancesLoading: false,
  purchaseAttendancesError: null,
  purchasePaymentData: action.purchasePaymentData,
});

const purchaseAttendancesFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  purchaseAttendancesLoading: false,
  purchaseAttendancesError: action.purchaseAttendancesError,
});

const exportAttendancesStart = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  exportAttendancesLoading: true,
});

const exportAttendancesSuccess = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  exportAttendancesLoading: false,
  exportAttendancesError: null,
  exportAttendances: action.exportAttendances,
});

const exportAttendancesFail = (
  state: AttendanceStateType,
  action: AttendanceActionType,
): AttendanceStateType => ({
  ...state,
  exportAttendancesLoading: false,
  exportAttendancesError: action.exportAttendancesError,
});

const resetPurchaseAttendancesStore = (
  state: AttendanceStateType,
): AttendanceStateType => ({
  ...state,
  purchasePaymentData: null,
});

const resetAttendanceStore = (): AttendanceStateType => ({
  ...initialState,
});

const completelyResetAttendanceStore = (): AttendanceStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: AttendanceActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_ATTENDANCE_START:
      return createAttendanceStart(state);
    case actionTypes.CREATE_ATTENDANCE_SUCCESS:
      return createAttendanceSuccess(state, action);
    case actionTypes.CREATE_ATTENDANCE_FAIL:
      return createAttendanceFail(state, action);
    case actionTypes.TOGGLE_ATTENDANCE_START:
      return toggleAttendanceStart(state);
    case actionTypes.TOGGLE_ATTENDANCE_SUCCESS:
      return toggleAttendanceSuccess(state);
    case actionTypes.TOGGLE_ATTENDANCE_FAIL:
      return toggleAttendanceFail(state, action);
    case actionTypes.EXPORT_ATTENDANCES_START:
      return exportAttendancesStart(state);
    case actionTypes.EXPORT_ATTENDANCES_SUCCESS:
      return exportAttendancesSuccess(state, action);
    case actionTypes.EXPORT_ATTENDANCES_FAIL:
      return exportAttendancesFail(state, action);
    case actionTypes.FETCH_CHILD_ATTENDANCES_START:
      return fetchChildAttendancesStart(state);
    case actionTypes.FETCH_CHILD_ATTENDANCES_SUCCESS:
      return fetchChildAttendancesSuccess(state, action);
    case actionTypes.FETCH_CHILD_ATTENDANCES_FAIL:
      return fetchChildAttendancesFail(state, action);
    case actionTypes.FETCH_PARENT_ATTENDANCES_START:
      return fetchParentAttendancesStart(state);
    case actionTypes.FETCH_PARENT_ATTENDANCES_SUCCESS:
      return fetchParentAttendancesSuccess(state, action);
    case actionTypes.FETCH_PARENT_ATTENDANCES_FAIL:
      return fetchParentAttendancesFail(state, action);
    case actionTypes.PURCHASE_ATTENDANCES_START:
      return purchaseAttendancesStart(state);
    case actionTypes.PURCHASE_ATTENDANCES_SUCCESS:
      return purchaseAttendancesSuccess(state, action);
    case actionTypes.PURCHASE_ATTENDANCES_FAIL:
      return purchaseAttendancesFail(state, action);
    case actionTypes.DELETE_ATTENDANCE_START:
      return deleteAttendanceStart(state);
    case actionTypes.DELETE_ATTENDANCE_SUCCESS:
      return deleteAttendanceSuccess(state);
    case actionTypes.DELETE_ATTENDANCE_FAIL:
      return deleteAttendanceFail(state, action);
    case actionTypes.RESET_PURCHASE_ATTENDANCES_STORE:
      return resetPurchaseAttendancesStore(state);
    case actionTypes.RESET_ATTENDANCE_STORE:
      return resetAttendanceStore();
    case actionTypes.LOGOUT:
      return completelyResetAttendanceStore();
    default:
      return state;
  }
};

export default reducer;
