export const FETCH_WANTED_TIMES_START = 'FETCH_WANTED_TIMES_START';
export const FETCH_WANTED_TIMES_SUCCESS = 'FETCH_WANTED_TIMES_SUCCESS';
export const FETCH_WANTED_TIMES_FAIL = 'FETCH_WANTED_TIMES_FAIL';

export const FETCH_SUBSCRIBED_TIMES_START = 'FETCH_SUBSCRIBED_TIMES_START';
export const FETCH_SUBSCRIBED_TIMES_SUCCESS = 'FETCH_SUBSCRIBED_TIMES_SUCCESS';
export const FETCH_SUBSCRIBED_TIMES_FAIL = 'FETCH_SUBSCRIBED_TIMES_FAIL';

export const CREATE_WANTED_TIME_START = 'CREATE_WANTED_TIME_START';
export const CREATE_WANTED_TIME_SUCCESS = 'CREATE_WANTED_TIME_SUCCESS';
export const CREATE_WANTED_TIME_FAIL = 'CREATE_WANTED_TIME_FAIL';

export const DELETE_WANTED_TIME_START = 'DELETE_WANTED_TIME_START';
export const DELETE_WANTED_TIME_SUCCESS = 'DELETE_WANTED_TIME_SUCCESS';
export const DELETE_WANTED_TIME_FAIL = 'DELETE_WANTED_TIME_FAIL';

export const RESET_WANTED_TIME_FORM_STORE = 'RESET_WANTED_TIME_FORM_STORE';
export const RESET_WANTED_TIME_STORE = 'RESET_WANTED_TIME_STORE';

export const LOGOUT = 'LOGOUT';

export type WantedTimeActionTypes =
  | typeof FETCH_WANTED_TIMES_START
  | typeof FETCH_WANTED_TIMES_SUCCESS
  | typeof FETCH_WANTED_TIMES_FAIL
  | typeof FETCH_SUBSCRIBED_TIMES_START
  | typeof FETCH_SUBSCRIBED_TIMES_SUCCESS
  | typeof FETCH_SUBSCRIBED_TIMES_FAIL
  | typeof CREATE_WANTED_TIME_START
  | typeof CREATE_WANTED_TIME_SUCCESS
  | typeof CREATE_WANTED_TIME_FAIL
  | typeof DELETE_WANTED_TIME_START
  | typeof DELETE_WANTED_TIME_SUCCESS
  | typeof DELETE_WANTED_TIME_FAIL
  | typeof RESET_WANTED_TIME_FORM_STORE
  | typeof RESET_WANTED_TIME_STORE
  | typeof LOGOUT;
