import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import 'react-toastify/dist/ReactToastify.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

if (process.env.REACT_APP_SENTRY_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://36b5b4bd6728480285ecce50bdb77305@o1101437.ingest.us.sentry.io/4506904239931392',
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENV,
    debug: true,
    tracesSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<App />);

reportWebVitals();
