import * as actionTypes from './actionTypes';
import { AdditionalServiceActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { AdditionalService } from '../../domain/AdditionalService';
import { ListResults } from '../../common/List/List';

export type AdditionalServiceStateType = {
  additionalServiceCreateLoading: boolean;
  additionalServiceCreateError: HttpError;
  additionalServiceCreateSuccess: boolean;
  createdAdditionalService: AdditionalService | null;
  additionalServiceUpdateLoading: boolean;
  additionalServiceUpdateError: HttpError;
  additionalServiceUpdateSuccess: boolean;
  additionalServiceDeleteLoading: boolean;
  additionalServiceDeleteError: HttpError;
  additionalServiceDeleteSuccess: boolean;
  additionalServicesList: ListResults<AdditionalService> | null;
  additionalServicesLoading: boolean;
  additionalServicesError: HttpError;
  additionalServicesUpdateNeeded: boolean;
  allAdditionalServices: AdditionalService[] | null;
  allAdditionalServicesError: HttpError;
  allAdditionalServicesLoading: boolean;
  additionalService: AdditionalService | null;
  additionalServiceError: HttpError;
  additionalServiceLoading: boolean;
};

export type AdditionalServiceActionType = AdditionalServiceStateType & {
  type: AdditionalServiceActionTypes;
};

export const initialState: AdditionalServiceStateType = {
  additionalServiceCreateLoading: false,
  additionalServiceCreateError: null,
  additionalServiceCreateSuccess: false,
  createdAdditionalService: null,
  additionalServiceUpdateLoading: false,
  additionalServiceUpdateError: null,
  additionalServiceUpdateSuccess: false,
  additionalServiceDeleteLoading: false,
  additionalServiceDeleteError: null,
  additionalServiceDeleteSuccess: false,
  additionalServicesUpdateNeeded: false,
  additionalServicesList: null,
  additionalServicesError: null,
  additionalServicesLoading: false,
  allAdditionalServices: null,
  allAdditionalServicesError: null,
  allAdditionalServicesLoading: false,
  additionalServiceError: null,
  additionalService: null,
  additionalServiceLoading: false,
};

const fetchAdditionalServicesStart = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServicesLoading: true,
  additionalServicesUpdateNeeded: false,
  additionalServiceUpdateSuccess: false,
  additionalServiceCreateSuccess: false,
});

const fetchAdditionalServicesSuccess = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServicesLoading: false,
  additionalServicesError: null,
  additionalServicesList: action.additionalServicesList,
});

const fetchAdditionalServicesFail = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServicesLoading: false,
  additionalServicesError: action.additionalServicesError,
});

const fetchAdditionalServiceStart = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceLoading: true,
  additionalServicesUpdateNeeded: false,
});

const fetchAdditionalServiceSuccess = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceLoading: false,
  additionalServiceError: null,
  additionalService: action.additionalService,
});

const fetchAdditionalServiceFail = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceLoading: false,
  additionalServiceError: action.additionalServiceError,
});

const fetchAllAdditionalServicesStart = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  allAdditionalServicesLoading: true,
  additionalServicesUpdateNeeded: false,
});

const fetchAllAdditionalServicesSuccess = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  allAdditionalServicesLoading: false,
  allAdditionalServicesError: null,
  allAdditionalServices: action.allAdditionalServices,
});

const fetchAllAdditionalServicesFail = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  allAdditionalServicesLoading: false,
  allAdditionalServicesError: action.allAdditionalServicesError,
});

const createAdditionalServiceStart = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceCreateLoading: true,
});

const createAdditionalServiceSuccess = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceCreateLoading: false,
  additionalServiceCreateError: null,
  additionalServiceCreateSuccess: true,
  createdAdditionalService: action.createdAdditionalService,
  additionalServicesUpdateNeeded: true,
});

const createAdditionalServiceFail = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceCreateLoading: false,
  additionalServiceCreateError: action.additionalServiceCreateError,
});

const updateAdditionalServiceStart = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceUpdateLoading: true,
  additionalServiceUpdateSuccess: false,
});

const updateAdditionalServiceSuccess = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceUpdateLoading: false,
  additionalServiceUpdateError: null,
  additionalServiceUpdateSuccess: true,
  additionalServicesUpdateNeeded: true,
});

const updateAdditionalServiceFail = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceUpdateLoading: false,
  additionalServiceUpdateError: action.additionalServiceUpdateError,
});

const deleteAdditionalServiceStart = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceDeleteLoading: true,
});

const deleteAdditionalServiceSuccess = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceDeleteLoading: false,
  additionalServiceDeleteError: null,
  additionalServiceDeleteSuccess: true,
  additionalServicesUpdateNeeded: true,
});

const deleteAdditionalServiceFail = (
  state: AdditionalServiceStateType,
  action: AdditionalServiceActionType,
): AdditionalServiceStateType => ({
  ...state,
  additionalServiceDeleteLoading: false,
  additionalServiceDeleteError: action.additionalServiceDeleteError,
});

const resetPartiallyAdditionalServiceStore = (
  state: AdditionalServiceStateType,
): AdditionalServiceStateType => ({
  ...initialState,
  additionalServicesList: state.additionalServicesList,
});

const resetAdditionalServiceStore = (): AdditionalServiceStateType => ({
  ...initialState,
});

const completelyResetAdditionalServiceStore =
  (): AdditionalServiceStateType => ({
    ...initialState,
  });

const reducer = (state = initialState, action: AdditionalServiceActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_ADDITIONAL_SERVICES_START:
      return fetchAdditionalServicesStart(state);
    case actionTypes.FETCH_ADDITIONAL_SERVICES_SUCCESS:
      return fetchAdditionalServicesSuccess(state, action);
    case actionTypes.FETCH_ADDITIONAL_SERVICES_FAIL:
      return fetchAdditionalServicesFail(state, action);
    case actionTypes.FETCH_ADDITIONAL_SERVICE_START:
      return fetchAdditionalServiceStart(state);
    case actionTypes.FETCH_ADDITIONAL_SERVICE_SUCCESS:
      return fetchAdditionalServiceSuccess(state, action);
    case actionTypes.FETCH_ADDITIONAL_SERVICE_FAIL:
      return fetchAdditionalServiceFail(state, action);
    case actionTypes.FETCH_ALL_ADDITIONAL_SERVICES_START:
      return fetchAllAdditionalServicesStart(state);
    case actionTypes.FETCH_ALL_ADDITIONAL_SERVICES_SUCCESS:
      return fetchAllAdditionalServicesSuccess(state, action);
    case actionTypes.FETCH_ALL_ADDITIONAL_SERVICES_FAIL:
      return fetchAllAdditionalServicesFail(state, action);
    case actionTypes.CREATE_ADDITIONAL_SERVICE_START:
      return createAdditionalServiceStart(state);
    case actionTypes.CREATE_ADDITIONAL_SERVICE_SUCCESS:
      return createAdditionalServiceSuccess(state, action);
    case actionTypes.CREATE_ADDITIONAL_SERVICE_FAIL:
      return createAdditionalServiceFail(state, action);
    case actionTypes.UPDATE_ADDITIONAL_SERVICE_START:
      return updateAdditionalServiceStart(state);
    case actionTypes.UPDATE_ADDITIONAL_SERVICE_SUCCESS:
      return updateAdditionalServiceSuccess(state);
    case actionTypes.UPDATE_ADDITIONAL_SERVICE_FAIL:
      return updateAdditionalServiceFail(state, action);
    case actionTypes.DELETE_ADDITIONAL_SERVICE_START:
      return deleteAdditionalServiceStart(state);
    case actionTypes.DELETE_ADDITIONAL_SERVICE_SUCCESS:
      return deleteAdditionalServiceSuccess(state);
    case actionTypes.DELETE_ADDITIONAL_SERVICE_FAIL:
      return deleteAdditionalServiceFail(state, action);
    case actionTypes.RESET_PARTIALLY_ADDITIONAL_SERVICE_STORE:
      return resetPartiallyAdditionalServiceStore(state);
    case actionTypes.RESET_ADDITIONAL_SERVICE_STORE:
      return resetAdditionalServiceStore();
    case actionTypes.LOGOUT:
      return completelyResetAdditionalServiceStore();
    default:
      return state;
  }
};

export default reducer;
