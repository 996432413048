export enum Roles {
  // eslint-disable-next-line no-unused-vars
  ADMIN = 'ADMIN',
  // eslint-disable-next-line no-unused-vars
  MANAGER = 'MANAGER',
  // eslint-disable-next-line no-unused-vars
  EMPLOYEE = 'EMPLOYEE',
  // eslint-disable-next-line no-unused-vars
  PARENT = 'PARENT',
}
