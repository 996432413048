import React from 'react';
import { Provider } from 'react-redux';
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux';
import thunk from 'redux-thunk';
import authReducer, { AuthStateType } from '../../store/auth/reducer';
import userReducer, { UserStateType } from '../../store/user/reducer';

import languageReducer, {
  LanguageStateType,
} from '../../store/language/reducer';
import translationReducer, {
  TranslationStateType,
} from '../../store/translation/reducer';
import branchReducer, { BranchStateType } from '../../store/branch/reducer';
import workTimeReducer, {
  WorkTimeStateType,
} from '../../store/work-time/reducer';
import employeeReducer, {
  EmployeeStateType,
} from '../../store/employee/reducer';
import childReducer, { ChildStateType } from '../../store/child/reducer';
import healthCheckReducer, {
  HealthCheckStateType,
} from '../../store/health-check/reducer';
import offDayReducer, { OffDayStateType } from '../../store/off-day/reducer';
import attendanceReducer, {
  AttendanceStateType,
} from '../../store/attendance/reducer';
import achievementReducer, {
  AchievementStateType,
} from '../../store/achievement/reducer';
import paymentReducer, { PaymentStateType } from '../../store/payment/reducer';
import commentReducer, { CommentStateType } from '../../store/comment/reducer';
import additionalServiceReducer, {
  AdditionalServiceStateType,
} from '../../store/additional-service/reducer';
import attendancePackageReducer, {
  AttendancePackageStateType,
} from '../../store/attendance-package/reducer';
import testingFormReducer, {
  TestingFormStateType,
} from '../../store/testing-form/reducer';
import wantedTimeReducer, {
  WantedTimeStateType,
} from '../../store/wanted-time/reducer';
import timeOffReducer, { TimeOffStateType } from '../../store/time-off/reducer';
import instance from '../Axios/axios-instance';
import * as authService from '../../store/auth/actions';

export type StoreState = {
  auth: AuthStateType;
  user: UserStateType;
  translation: TranslationStateType;
  language: LanguageStateType;
  branch: BranchStateType;
  workTime: WorkTimeStateType;
  employee: EmployeeStateType;
  child: ChildStateType;
  healthCheck: HealthCheckStateType;
  offDay: OffDayStateType;
  achievement: AchievementStateType;
  attendance: AttendanceStateType;
  payment: PaymentStateType;
  comment: CommentStateType;
  testingForm: TestingFormStateType;
  attendancePackage: AttendancePackageStateType;
  additionalService: AdditionalServiceStateType;
  wantedTime: WantedTimeStateType;
  timeOff: TimeOffStateType;
};

type Props = {
  children?: any;
};

const rootReducer = combineReducers<StoreState>({
  auth: authReducer,
  user: userReducer,
  translation: translationReducer,
  language: languageReducer,
  branch: branchReducer,
  workTime: workTimeReducer,
  employee: employeeReducer,
  child: childReducer,
  healthCheck: healthCheckReducer,
  offDay: offDayReducer,
  achievement: achievementReducer,
  attendance: attendanceReducer,
  payment: paymentReducer,
  comment: commentReducer,
  testingForm: testingFormReducer,
  attendancePackage: attendancePackageReducer,
  additionalService: additionalServiceReducer,
  wantedTime: wantedTimeReducer,
  timeOff: timeOffReducer,
});

export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export const store: Store<any, any> = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk)),
);

const { dispatch } = store;
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response.status === 401 &&
      error.response.config.url !== '/token'
    ) {
      dispatch(authService.logout());
    }

    return Promise.reject(error);
  },
);

const StoreProvider = ({ children }: Props) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreProvider;
