export const FETCH_ADDITIONAL_SERVICES_START =
  'FETCH_ADDITIONAL_SERVICES_START';
export const FETCH_ADDITIONAL_SERVICES_SUCCESS =
  'FETCH_ADDITIONAL_SERVICES_SUCCESS';
export const FETCH_ADDITIONAL_SERVICES_FAIL = 'FETCH_ADDITIONAL_SERVICES_FAIL';

export const FETCH_ADDITIONAL_SERVICE_START = 'FETCH_ADDITIONAL_SERVICE_START';
export const FETCH_ADDITIONAL_SERVICE_SUCCESS =
  'FETCH_ADDITIONAL_SERVICE_SUCCESS';
export const FETCH_ADDITIONAL_SERVICE_FAIL = 'FETCH_ADDITIONAL_SERVICE_FAIL';

export const FETCH_ALL_ADDITIONAL_SERVICES_START =
  'FETCH_ALL_ADDITIONAL_SERVICES_START';
export const FETCH_ALL_ADDITIONAL_SERVICES_SUCCESS =
  'FETCH_ALL_ADDITIONAL_SERVICES_SUCCESS';
export const FETCH_ALL_ADDITIONAL_SERVICES_FAIL =
  'FETCH_ALL_ADDITIONAL_SERVICES_FAIL';

export const CREATE_ADDITIONAL_SERVICE_START =
  'CREATE_ADDITIONAL_SERVICE_START';
export const CREATE_ADDITIONAL_SERVICE_SUCCESS =
  'CREATE_ADDITIONAL_SERVICE_SUCCESS';
export const CREATE_ADDITIONAL_SERVICE_FAIL = 'CREATE_ADDITIONAL_SERVICE_FAIL';

export const UPDATE_ADDITIONAL_SERVICE_START =
  'UPDATE_ADDITIONAL_SERVICE_START';
export const UPDATE_ADDITIONAL_SERVICE_SUCCESS =
  'UPDATE_ADDITIONAL_SERVICE_SUCCESS';
export const UPDATE_ADDITIONAL_SERVICE_FAIL = 'UPDATE_ADDITIONAL_SERVICE_FAIL';

export const DELETE_ADDITIONAL_SERVICE_START =
  'DELETE_ADDITIONAL_SERVICE_START';
export const DELETE_ADDITIONAL_SERVICE_SUCCESS =
  'DELETE_ADDITIONAL_SERVICE_SUCCESS';
export const DELETE_ADDITIONAL_SERVICE_FAIL = 'DELETE_ADDITIONAL_SERVICE_FAIL';

export const RESET_ADDITIONAL_SERVICE_STORE = 'RESET_ADDITIONAL_SERVICE_STORE';
export const RESET_PARTIALLY_ADDITIONAL_SERVICE_STORE =
  'RESET_PARTIALLY_ADDITIONAL_SERVICE_STORE';

export const LOGOUT = 'LOGOUT';

export type AdditionalServiceActionTypes =
  | typeof FETCH_ADDITIONAL_SERVICES_START
  | typeof FETCH_ADDITIONAL_SERVICES_SUCCESS
  | typeof FETCH_ADDITIONAL_SERVICES_FAIL
  | typeof FETCH_ADDITIONAL_SERVICE_START
  | typeof FETCH_ADDITIONAL_SERVICE_SUCCESS
  | typeof FETCH_ADDITIONAL_SERVICE_FAIL
  | typeof FETCH_ALL_ADDITIONAL_SERVICES_START
  | typeof FETCH_ALL_ADDITIONAL_SERVICES_SUCCESS
  | typeof FETCH_ALL_ADDITIONAL_SERVICES_FAIL
  | typeof CREATE_ADDITIONAL_SERVICE_START
  | typeof CREATE_ADDITIONAL_SERVICE_SUCCESS
  | typeof CREATE_ADDITIONAL_SERVICE_FAIL
  | typeof UPDATE_ADDITIONAL_SERVICE_START
  | typeof UPDATE_ADDITIONAL_SERVICE_SUCCESS
  | typeof UPDATE_ADDITIONAL_SERVICE_FAIL
  | typeof DELETE_ADDITIONAL_SERVICE_START
  | typeof DELETE_ADDITIONAL_SERVICE_SUCCESS
  | typeof DELETE_ADDITIONAL_SERVICE_FAIL
  | typeof RESET_ADDITIONAL_SERVICE_STORE
  | typeof RESET_PARTIALLY_ADDITIONAL_SERVICE_STORE
  | typeof LOGOUT;
