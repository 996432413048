export const FETCH_BRANCHES_START = 'FETCH_BRANCHES_START';
export const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_FAIL = 'FETCH_BRANCHES_FAIL';

export const FETCH_BRANCH_START = 'FETCH_BRANCH_START';
export const FETCH_BRANCH_SUCCESS = 'FETCH_BRANCH_SUCCESS';
export const FETCH_BRANCH_FAIL = 'FETCH_BRANCH_FAIL';

export const FETCH_BRANCH_OPTIONS_START = 'FETCH_BRANCH_OPTIONS_START';
export const FETCH_BRANCH_OPTIONS_SUCCESS = 'FETCH_BRANCH_OPTIONS_SUCCESS';
export const FETCH_BRANCH_OPTIONS_FAIL = 'FETCH_BRANCH_OPTIONS_FAIL';

export const FETCH_BRANCH_ALL_START = 'FETCH_BRANCH_ALL_START';
export const FETCH_BRANCH_ALL_SUCCESS = 'FETCH_BRANCH_ALL_SUCCESS';
export const FETCH_BRANCH_ALL_FAIL = 'FETCH_BRANCH_ALL_FAIL';

export const UPDATE_BRANCH_START = 'UPDATE_BRANCH_START';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAIL = 'UPDATE_BRANCH_FAIL';

export const SELECT_BRANCH = 'SELECT_BRANCH';

export const RESET_BRANCH_STORE = 'RESET_BRANCH_STORE';

export const LOGOUT = 'LOGOUT';

export type BranchActionTypes =
  | typeof FETCH_BRANCHES_START
  | typeof FETCH_BRANCHES_SUCCESS
  | typeof FETCH_BRANCHES_FAIL
  | typeof FETCH_BRANCH_START
  | typeof FETCH_BRANCH_SUCCESS
  | typeof FETCH_BRANCH_FAIL
  | typeof FETCH_BRANCH_OPTIONS_START
  | typeof FETCH_BRANCH_OPTIONS_SUCCESS
  | typeof FETCH_BRANCH_OPTIONS_FAIL
  | typeof FETCH_BRANCH_ALL_START
  | typeof FETCH_BRANCH_ALL_SUCCESS
  | typeof FETCH_BRANCH_ALL_FAIL
  | typeof UPDATE_BRANCH_START
  | typeof UPDATE_BRANCH_SUCCESS
  | typeof UPDATE_BRANCH_FAIL
  | typeof SELECT_BRANCH
  | typeof RESET_BRANCH_STORE
  | typeof LOGOUT;
