import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { Comment } from '../../domain/Comment';
import { CommentActionTypes } from './actionTypes';
import { ListResults } from '../../common/List/List';

export type CommentStateType = {
  commentCreateLoading: boolean;
  commentCreateError: HttpError;
  commentCreateSuccess: boolean;
  createdComment: Comment | null;
  commentUpdateLoading: boolean;
  commentUpdateError: HttpError;
  commentUpdateSuccess: boolean;
  commentDeleteLoading: boolean;
  commentDeleteError: HttpError;
  commentDeleteSuccess: boolean;
  commentListLoading: boolean;
  commentList: ListResults<Comment> | null;
  commentListError: HttpError;
  commentListUpdateNeeded: boolean;
};

export type CommentActionType = CommentStateType & {
  type: CommentActionTypes;
};

export const initialState: CommentStateType = {
  commentCreateLoading: false,
  commentCreateError: null,
  commentCreateSuccess: false,
  createdComment: null,
  commentUpdateLoading: false,
  commentUpdateError: null,
  commentUpdateSuccess: false,
  commentDeleteLoading: false,
  commentDeleteError: null,
  commentDeleteSuccess: false,
  commentList: null,
  commentListError: null,
  commentListLoading: false,
  commentListUpdateNeeded: false,
};

const fetchCommentsStart = (state: CommentStateType): CommentStateType => ({
  ...state,
  commentListUpdateNeeded: false,
  commentListLoading: true,
  commentCreateSuccess: false,
  commentUpdateSuccess: false,
});

const fetchCommentsSuccess = (
  state: CommentStateType,
  action: CommentActionType,
): CommentStateType => ({
  ...state,
  commentListLoading: false,
  commentListError: null,
  commentList: action.commentList,
});

const fetchCommentsFail = (
  state: CommentStateType,
  action: CommentActionType,
): CommentStateType => ({
  ...state,
  commentListLoading: false,
  commentListError: action.commentListError,
});

const createCommentStart = (state: CommentStateType): CommentStateType => ({
  ...state,
  commentCreateLoading: true,
});

const createCommentSuccess = (
  state: CommentStateType,
  action: CommentActionType,
): CommentStateType => ({
  ...state,
  commentCreateLoading: false,
  commentCreateError: null,
  commentCreateSuccess: true,
  commentListUpdateNeeded: true,
  createdComment: action.createdComment,
});

const createCommentFail = (
  state: CommentStateType,
  action: CommentActionType,
): CommentStateType => ({
  ...state,
  commentCreateLoading: false,
  commentCreateError: action.commentCreateError,
});

const updateCommentStart = (state: CommentStateType): CommentStateType => ({
  ...state,
  commentUpdateLoading: true,
  commentUpdateSuccess: false,
});

const updateCommentSuccess = (state: CommentStateType): CommentStateType => ({
  ...state,
  commentUpdateLoading: false,
  commentUpdateError: null,
  commentUpdateSuccess: true,
  commentListUpdateNeeded: true,
});

const updateCommentFail = (
  state: CommentStateType,
  action: CommentActionType,
): CommentStateType => ({
  ...state,
  commentUpdateLoading: false,
  commentUpdateError: action.commentUpdateError,
});

const deleteCommentStart = (state: CommentStateType): CommentStateType => ({
  ...state,
  commentDeleteLoading: true,
});

const deleteCommentSuccess = (state: CommentStateType): CommentStateType => ({
  ...state,
  commentDeleteLoading: false,
  commentDeleteError: null,
  commentDeleteSuccess: true,
  commentListUpdateNeeded: true,
});

const deleteCommentFail = (
  state: CommentStateType,
  action: CommentActionType,
): CommentStateType => ({
  ...state,
  commentDeleteLoading: false,
  commentDeleteError: action.commentDeleteError,
});

const resetCommentStore = (): CommentStateType => ({
  ...initialState,
});

const completelyResetCommentStore = (): CommentStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: CommentActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_COMMENTS_START:
      return fetchCommentsStart(state);
    case actionTypes.FETCH_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action);
    case actionTypes.FETCH_COMMENTS_FAIL:
      return fetchCommentsFail(state, action);
    case actionTypes.CREATE_COMMENT_START:
      return createCommentStart(state);
    case actionTypes.CREATE_COMMENT_SUCCESS:
      return createCommentSuccess(state, action);
    case actionTypes.CREATE_COMMENT_FAIL:
      return createCommentFail(state, action);
    case actionTypes.UPDATE_COMMENT_START:
      return updateCommentStart(state);
    case actionTypes.UPDATE_COMMENT_SUCCESS:
      return updateCommentSuccess(state);
    case actionTypes.UPDATE_COMMENT_FAIL:
      return updateCommentFail(state, action);
    case actionTypes.DELETE_COMMENT_START:
      return deleteCommentStart(state);
    case actionTypes.DELETE_COMMENT_SUCCESS:
      return deleteCommentSuccess(state);
    case actionTypes.DELETE_COMMENT_FAIL:
      return deleteCommentFail(state, action);
    case actionTypes.RESET_COMMENT_STORE:
      return resetCommentStore();
    case actionTypes.LOGOUT:
      return completelyResetCommentStore();
    default:
      return state;
  }
};

export default reducer;
