export const FETCH_ATTENDANCE_PACKAGE_START = 'FETCH_ATTENDANCE_PACKAGE_START';
export const FETCH_ATTENDANCE_PACKAGE_SUCCESS =
  'FETCH_ATTENDANCE_PACKAGE_SUCCESS';
export const FETCH_ATTENDANCE_PACKAGE_FAIL = 'FETCH_ATTENDANCE_PACKAGE_FAIL';

export const FETCH_ALL_ATTENDANCE_PACKAGES_START =
  'FETCH_ALL_ATTENDANCE_PACKAGES_START';
export const FETCH_ALL_ATTENDANCE_PACKAGES_SUCCESS =
  'FETCH_ALL_ATTENDANCE_PACKAGES_SUCCESS';
export const FETCH_ALL_ATTENDANCE_PACKAGES_FAIL =
  'FETCH_ALL_ATTENDANCE_PACKAGES_FAIL';

export const FETCH_PACKAGE_WORK_TIMES_START = 'FETCH_PACKAGE_WORK_TIMES_START';
export const FETCH_PACKAGE_WORK_TIMES_SUCCESS =
  'FETCH_PACKAGE_WORK_TIMES_SUCCESS';
export const FETCH_PACKAGE_WORK_TIMES_FAIL = 'FETCH_PACKAGE_WORK_TIMES_FAIL';

export const CREATE_ATTENDANCE_PACKAGE_START =
  'CREATE_ATTENDANCE_PACKAGE_START';
export const CREATE_ATTENDANCE_PACKAGE_SUCCESS =
  'CREATE_ATTENDANCE_PACKAGE_SUCCESS';
export const CREATE_ATTENDANCE_PACKAGE_FAIL = 'CREATE_ATTENDANCE_PACKAGE_FAIL';

export const UPDATE_ATTENDANCE_PACKAGE_START =
  'UPDATE_ATTENDANCE_PACKAGE_START';
export const UPDATE_ATTENDANCE_PACKAGE_SUCCESS =
  'UPDATE_ATTENDANCE_PACKAGE_SUCCESS';
export const UPDATE_ATTENDANCE_PACKAGE_FAIL = 'UPDATE_ATTENDANCE_PACKAGE_FAIL';

export const DELETE_ATTENDANCE_PACKAGE_START =
  'DELETE_ATTENDANCE_PACKAGE_START';
export const DELETE_ATTENDANCE_PACKAGE_SUCCESS =
  'DELETE_ATTENDANCE_PACKAGE_SUCCESS';
export const DELETE_ATTENDANCE_PACKAGE_FAIL = 'DELETE_ATTENDANCE_PACKAGE_FAIL';

export const RESET_ATTENDANCE_PACKAGE_STORE = 'RESET_ATTENDANCE_PACKAGE_STORE';
export const RESET_PARTIALLY_ATTENDANCE_PACKAGE_STORE =
  'RESET_PARTIALLY_ATTENDANCE_PACKAGE_STORE';

export const LOGOUT = 'LOGOUT';

export type AttendancePackageActionTypes =
  | typeof FETCH_ATTENDANCE_PACKAGE_START
  | typeof FETCH_ATTENDANCE_PACKAGE_SUCCESS
  | typeof FETCH_ATTENDANCE_PACKAGE_FAIL
  | typeof FETCH_ALL_ATTENDANCE_PACKAGES_START
  | typeof FETCH_ALL_ATTENDANCE_PACKAGES_SUCCESS
  | typeof FETCH_ALL_ATTENDANCE_PACKAGES_FAIL
  | typeof FETCH_PACKAGE_WORK_TIMES_START
  | typeof FETCH_PACKAGE_WORK_TIMES_SUCCESS
  | typeof FETCH_PACKAGE_WORK_TIMES_FAIL
  | typeof CREATE_ATTENDANCE_PACKAGE_START
  | typeof CREATE_ATTENDANCE_PACKAGE_SUCCESS
  | typeof CREATE_ATTENDANCE_PACKAGE_FAIL
  | typeof UPDATE_ATTENDANCE_PACKAGE_START
  | typeof UPDATE_ATTENDANCE_PACKAGE_SUCCESS
  | typeof UPDATE_ATTENDANCE_PACKAGE_FAIL
  | typeof DELETE_ATTENDANCE_PACKAGE_START
  | typeof DELETE_ATTENDANCE_PACKAGE_SUCCESS
  | typeof DELETE_ATTENDANCE_PACKAGE_FAIL
  | typeof RESET_ATTENDANCE_PACKAGE_STORE
  | typeof RESET_PARTIALLY_ATTENDANCE_PACKAGE_STORE
  | typeof LOGOUT;
