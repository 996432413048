import * as actionTypes from './actionTypes';
import { TestingFormActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { TestingForm } from '../../domain/TestingForm';
import { ListResults } from '../../common/List/List';

export type TestingFormStateType = {
  testingFormCreateLoading: boolean;
  testingFormCreateError: HttpError;
  testingFormCreateSuccess: boolean;
  createdTestingForm: TestingForm | null;
  testingFormUpdateLoading: boolean;
  testingFormUpdateError: HttpError;
  testingFormUpdateSuccess: boolean;
  testingFormDeleteLoading: boolean;
  testingFormDeleteError: HttpError;
  testingFormDeleteSuccess: boolean;
  testingFormsList: ListResults<TestingForm> | null;
  testingFormsLoading: boolean;
  testingFormsError: HttpError;
  testingFormsUpdateNeeded: boolean;
  downloadTestingFormLoading: boolean;
  downloadTestingFormError: HttpError;
  sendTestingFormLoading: boolean;
  sendTestingFormError: HttpError;
};

export type TestingFormActionType = TestingFormStateType & {
  type: TestingFormActionTypes;
};

export const initialState: TestingFormStateType = {
  testingFormCreateLoading: false,
  testingFormCreateError: null,
  testingFormCreateSuccess: false,
  createdTestingForm: null,
  testingFormUpdateLoading: false,
  testingFormUpdateError: null,
  testingFormUpdateSuccess: false,
  testingFormDeleteLoading: false,
  testingFormDeleteError: null,
  testingFormDeleteSuccess: false,
  testingFormsUpdateNeeded: false,
  testingFormsList: null,
  testingFormsError: null,
  testingFormsLoading: false,
  downloadTestingFormError: null,
  downloadTestingFormLoading: false,
  sendTestingFormError: null,
  sendTestingFormLoading: false,
};

const fetchTestingFormsStart = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  testingFormsLoading: true,
  testingFormsUpdateNeeded: false,
});

const fetchTestingFormsSuccess = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  testingFormsLoading: false,
  testingFormsError: null,
  testingFormsList: action.testingFormsList,
});

const fetchTestingFormsFail = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  testingFormsLoading: false,
  testingFormsError: action.testingFormsError,
});

const createTestingFormStart = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  testingFormCreateLoading: true,
});

const createTestingFormSuccess = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  testingFormCreateLoading: false,
  testingFormCreateError: null,
  testingFormCreateSuccess: true,
  createdTestingForm: action.createdTestingForm,
  testingFormsUpdateNeeded: true,
});

const createTestingFormFail = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  testingFormCreateLoading: false,
  testingFormCreateError: action.testingFormCreateError,
});

const updateTestingFormStart = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  testingFormUpdateLoading: true,
  testingFormUpdateSuccess: false,
});

const updateTestingFormSuccess = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  testingFormUpdateLoading: false,
  testingFormUpdateError: null,
  testingFormUpdateSuccess: true,
  testingFormsUpdateNeeded: true,
});

const updateTestingFormFail = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  testingFormUpdateLoading: false,
  testingFormUpdateError: action.testingFormUpdateError,
});

const deleteTestingFormStart = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  testingFormDeleteLoading: true,
});

const deleteTestingFormSuccess = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  testingFormDeleteLoading: false,
  testingFormDeleteError: null,
  testingFormDeleteSuccess: true,
  testingFormsUpdateNeeded: true,
});

const deleteTestingFormFail = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  testingFormDeleteLoading: false,
  testingFormDeleteError: action.testingFormDeleteError,
});

const downloadTestingFormStart = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  downloadTestingFormLoading: true,
});

const downloadTestingFormSuccess = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  downloadTestingFormLoading: false,
  downloadTestingFormError: null,
});

const downloadTestingFormFail = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  downloadTestingFormLoading: false,
  downloadTestingFormError: action.downloadTestingFormError,
});

const sendTestingFormStart = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  sendTestingFormLoading: true,
});

const sendTestingFormSuccess = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...state,
  sendTestingFormLoading: false,
  sendTestingFormError: null,
  testingFormsUpdateNeeded: true,
});

const sendTestingFormFail = (
  state: TestingFormStateType,
  action: TestingFormActionType,
): TestingFormStateType => ({
  ...state,
  sendTestingFormLoading: false,
  sendTestingFormError: action.sendTestingFormError,
});

const resetPartiallyTestingFormStore = (
  state: TestingFormStateType,
): TestingFormStateType => ({
  ...initialState,
  testingFormsList: state.testingFormsList,
});

const resetTestingFormStore = (): TestingFormStateType => ({
  ...initialState,
});

const completelyResetTestingFormStore = (): TestingFormStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TestingFormActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TESTING_FORMS_START:
      return fetchTestingFormsStart(state);
    case actionTypes.FETCH_TESTING_FORMS_SUCCESS:
      return fetchTestingFormsSuccess(state, action);
    case actionTypes.FETCH_TESTING_FORMS_FAIL:
      return fetchTestingFormsFail(state, action);
    case actionTypes.CREATE_TESTING_FORM_START:
      return createTestingFormStart(state);
    case actionTypes.CREATE_TESTING_FORM_SUCCESS:
      return createTestingFormSuccess(state, action);
    case actionTypes.CREATE_TESTING_FORM_FAIL:
      return createTestingFormFail(state, action);
    case actionTypes.UPDATE_TESTING_FORM_START:
      return updateTestingFormStart(state);
    case actionTypes.UPDATE_TESTING_FORM_SUCCESS:
      return updateTestingFormSuccess(state);
    case actionTypes.UPDATE_TESTING_FORM_FAIL:
      return updateTestingFormFail(state, action);
    case actionTypes.DELETE_TESTING_FORM_START:
      return deleteTestingFormStart(state);
    case actionTypes.DELETE_TESTING_FORM_SUCCESS:
      return deleteTestingFormSuccess(state);
    case actionTypes.DELETE_TESTING_FORM_FAIL:
      return deleteTestingFormFail(state, action);
    case actionTypes.DOWNLOAD_TESTING_FORM_START:
      return downloadTestingFormStart(state);
    case actionTypes.DOWNLOAD_TESTING_FORM_SUCCESS:
      return downloadTestingFormSuccess(state);
    case actionTypes.DOWNLOAD_TESTING_FORM_FAIL:
      return downloadTestingFormFail(state, action);
    case actionTypes.SEND_TESTING_FORM_START:
      return sendTestingFormStart(state);
    case actionTypes.SEND_TESTING_FORM_SUCCESS:
      return sendTestingFormSuccess(state);
    case actionTypes.SEND_TESTING_FORM_FAIL:
      return sendTestingFormFail(state, action);
    case actionTypes.RESET_PARTIALLY_TESTING_FORM_STORE:
      return resetPartiallyTestingFormStore(state);
    case actionTypes.RESET_TESTING_FORM_STORE:
      return resetTestingFormStore();
    case actionTypes.LOGOUT:
      return completelyResetTestingFormStore();
    default:
      return state;
  }
};

export default reducer;
