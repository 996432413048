import React, { ReactNode } from 'react';
import cx from 'classnames';
import styles from './Label.module.scss';

type Props = {
  content?: string;
  children?: ReactNode;
  variant: string;
  className?: string;
  onClick?: (event: any) => void;
};

export const Label = ({
  content,
  className,
  variant,
  children,
  onClick,
}: Props) => (
  <span
    onClick={onClick}
    className={cx(styles.label, className, {
      [styles.success]: variant === 'success',
      [styles.error]: variant === 'error',
      [styles.info]: variant === 'info',
      [styles.warning]: variant === 'warning',
      [styles.clickable]: !!onClick,
    })}
  >
    {content}
    {children}
  </span>
);

export default Label;
