import * as actionTypes from './actionTypes';
import { OffDayActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { OffDay } from '../../domain/OffDay';
import { ListResults } from '../../common/List/List';

export type OffDayStateType = {
  offDayCreateLoading: boolean;
  offDayCreateError: HttpError;
  offDayCreateSuccess: boolean;
  createdOffDay: OffDay | null;
  offDayUpdateLoading: boolean;
  offDayUpdateError: HttpError;
  offDayUpdateSuccess: boolean;
  offDayDeleteLoading: boolean;
  offDayDeleteError: HttpError;
  offDayDeleteSuccess: boolean;
  offDaysList: ListResults<OffDay> | null;
  offDaysLoading: boolean;
  offDaysError: HttpError;
  offDaysUpdateNeeded: boolean;
};

export type OffDayActionType = OffDayStateType & {
  type: OffDayActionTypes;
};

export const initialState: OffDayStateType = {
  offDayCreateLoading: false,
  offDayCreateError: null,
  offDayCreateSuccess: false,
  createdOffDay: null,
  offDayUpdateLoading: false,
  offDayUpdateError: null,
  offDayUpdateSuccess: false,
  offDayDeleteLoading: false,
  offDayDeleteError: null,
  offDayDeleteSuccess: false,
  offDaysUpdateNeeded: false,
  offDaysList: null,
  offDaysError: null,
  offDaysLoading: false,
};

const fetchOffDaysStart = (state: OffDayStateType): OffDayStateType => ({
  ...state,
  offDaysLoading: true,
  offDaysUpdateNeeded: false,
});

const fetchOffDaysSuccess = (
  state: OffDayStateType,
  action: OffDayActionType,
): OffDayStateType => ({
  ...state,
  offDaysLoading: false,
  offDaysError: null,
  offDaysList: action.offDaysList,
});

const fetchOffDaysFail = (
  state: OffDayStateType,
  action: OffDayActionType,
): OffDayStateType => ({
  ...state,
  offDaysLoading: false,
  offDaysError: action.offDaysError,
});

const createOffDayStart = (state: OffDayStateType): OffDayStateType => ({
  ...state,
  offDayCreateLoading: true,
});

const createOffDaySuccess = (
  state: OffDayStateType,
  action: OffDayActionType,
): OffDayStateType => ({
  ...state,
  offDayCreateLoading: false,
  offDayCreateError: null,
  offDayCreateSuccess: true,
  createdOffDay: action.createdOffDay,
  offDaysUpdateNeeded: true,
});

const createOffDayFail = (
  state: OffDayStateType,
  action: OffDayActionType,
): OffDayStateType => ({
  ...state,
  offDayCreateLoading: false,
  offDayCreateError: action.offDayCreateError,
});

const updateOffDayStart = (state: OffDayStateType): OffDayStateType => ({
  ...state,
  offDayUpdateLoading: true,
  offDayUpdateSuccess: false,
});

const updateOffDaySuccess = (state: OffDayStateType): OffDayStateType => ({
  ...state,
  offDayUpdateLoading: false,
  offDayUpdateError: null,
  offDayUpdateSuccess: true,
  offDaysUpdateNeeded: true,
});

const updateOffDayFail = (
  state: OffDayStateType,
  action: OffDayActionType,
): OffDayStateType => ({
  ...state,
  offDayUpdateLoading: false,
  offDayUpdateError: action.offDayUpdateError,
});

const deleteOffDayStart = (state: OffDayStateType): OffDayStateType => ({
  ...state,
  offDayDeleteLoading: true,
});

const deleteOffDaySuccess = (state: OffDayStateType): OffDayStateType => ({
  ...state,
  offDayDeleteLoading: false,
  offDayDeleteError: null,
  offDayDeleteSuccess: true,
  offDaysUpdateNeeded: true,
});

const deleteOffDayFail = (
  state: OffDayStateType,
  action: OffDayActionType,
): OffDayStateType => ({
  ...state,
  offDayDeleteLoading: false,
  offDayDeleteError: action.offDayDeleteError,
});

const resetPartiallyOffDayStore = (
  state: OffDayStateType,
): OffDayStateType => ({
  ...initialState,
  offDaysList: state.offDaysList,
});

const resetOffDayStore = (): OffDayStateType => ({
  ...initialState,
});

const completelyResetOffDayStore = (): OffDayStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: OffDayActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_OFF_DAY_START:
      return fetchOffDaysStart(state);
    case actionTypes.FETCH_OFF_DAY_SUCCESS:
      return fetchOffDaysSuccess(state, action);
    case actionTypes.FETCH_OFF_DAY_FAIL:
      return fetchOffDaysFail(state, action);
    case actionTypes.CREATE_OFF_DAY_START:
      return createOffDayStart(state);
    case actionTypes.CREATE_OFF_DAY_SUCCESS:
      return createOffDaySuccess(state, action);
    case actionTypes.CREATE_OFF_DAY_FAIL:
      return createOffDayFail(state, action);
    case actionTypes.UPDATE_OFF_DAY_START:
      return updateOffDayStart(state);
    case actionTypes.UPDATE_OFF_DAY_SUCCESS:
      return updateOffDaySuccess(state);
    case actionTypes.UPDATE_OFF_DAY_FAIL:
      return updateOffDayFail(state, action);
    case actionTypes.DELETE_OFF_DAY_START:
      return deleteOffDayStart(state);
    case actionTypes.DELETE_OFF_DAY_SUCCESS:
      return deleteOffDaySuccess(state);
    case actionTypes.DELETE_OFF_DAY_FAIL:
      return deleteOffDayFail(state, action);
    case actionTypes.RESET_PARTIALLY_OFF_DAY_STORE:
      return resetPartiallyOffDayStore(state);
    case actionTypes.RESET_OFF_DAY_STORE:
      return resetOffDayStore();
    case actionTypes.LOGOUT:
      return completelyResetOffDayStore();
    default:
      return state;
  }
};

export default reducer;
