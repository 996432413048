import * as actionTypes from './actionTypes';
import { TimeOffActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { TimeOff } from '../../domain/TimeOff';
import { ListResults } from '../../common/List/List';

export type TimeOffStateType = {
  timeOffCreateLoading: boolean;
  timeOffCreateError: HttpError;
  timeOffCreateSuccess: boolean;
  createdTimeOff: TimeOff | null;
  timeOffUpdateLoading: boolean;
  timeOffUpdateError: HttpError;
  timeOffUpdateSuccess: boolean;
  timeOffDeleteLoading: boolean;
  timeOffDeleteError: HttpError;
  timeOffDeleteSuccess: boolean;
  timeOffList: ListResults<TimeOff> | null;
  timeOffLoading: boolean;
  timeOffError: HttpError;
  timeOffUpdateNeeded: boolean;
  allTimeOffs: TimeOff[] | null;
  allTimeOffsError: HttpError;
  allTimeOffsLoading: boolean;
};

export type TimeOffActionType = TimeOffStateType & {
  type: TimeOffActionTypes;
};

export const initialState: TimeOffStateType = {
  timeOffCreateLoading: false,
  timeOffCreateError: null,
  timeOffCreateSuccess: false,
  createdTimeOff: null,
  timeOffUpdateLoading: false,
  timeOffUpdateError: null,
  timeOffUpdateSuccess: false,
  timeOffDeleteLoading: false,
  timeOffDeleteError: null,
  timeOffDeleteSuccess: false,
  timeOffUpdateNeeded: false,
  timeOffList: null,
  timeOffError: null,
  timeOffLoading: false,
  allTimeOffs: null,
  allTimeOffsError: null,
  allTimeOffsLoading: false,
};

const fetchTimeOffsStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffLoading: true,
  timeOffUpdateNeeded: false,
});

const fetchTimeOffsSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffLoading: false,
  timeOffError: null,
  timeOffList: action.timeOffList,
});

const fetchTimeOffsFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffLoading: false,
  timeOffError: action.timeOffError,
});

const fetchAllTimeOffsStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  allTimeOffsLoading: true,
});

const fetchAllTimeOffsSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  allTimeOffsLoading: false,
  allTimeOffsError: null,
  allTimeOffs: action.allTimeOffs,
});

const fetchAllTimeOffsFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  allTimeOffsLoading: false,
  allTimeOffsError: action.allTimeOffsError,
});

const createTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffCreateLoading: true,
});

const createTimeOffSuccess = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffCreateLoading: false,
  timeOffCreateError: null,
  timeOffCreateSuccess: true,
  createdTimeOff: action.createdTimeOff,
  timeOffUpdateNeeded: true,
});

const createTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffCreateLoading: false,
  timeOffCreateError: action.timeOffCreateError,
});

const updateTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffUpdateLoading: true,
  timeOffUpdateSuccess: false,
});

const updateTimeOffSuccess = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffUpdateLoading: false,
  timeOffUpdateError: null,
  timeOffUpdateSuccess: true,
  timeOffUpdateNeeded: true,
});

const updateTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffUpdateLoading: false,
  timeOffUpdateError: action.timeOffUpdateError,
});

const deleteTimeOffStart = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffDeleteLoading: true,
});

const deleteTimeOffSuccess = (state: TimeOffStateType): TimeOffStateType => ({
  ...state,
  timeOffDeleteLoading: false,
  timeOffDeleteError: null,
  timeOffDeleteSuccess: true,
  timeOffUpdateNeeded: true,
});

const deleteTimeOffFail = (
  state: TimeOffStateType,
  action: TimeOffActionType,
): TimeOffStateType => ({
  ...state,
  timeOffDeleteLoading: false,
  timeOffDeleteError: action.timeOffDeleteError,
});

const resetPartiallyTimeOffStore = (
  state: TimeOffStateType,
): TimeOffStateType => ({
  ...initialState,
  timeOffList: state.timeOffList,
});

const resetTimeOffStore = (): TimeOffStateType => ({
  ...initialState,
});

const completelyResetTimeOffStore = (): TimeOffStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: TimeOffActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_TIME_OFF_START:
      return fetchTimeOffsStart(state);
    case actionTypes.FETCH_TIME_OFF_SUCCESS:
      return fetchTimeOffsSuccess(state, action);
    case actionTypes.FETCH_TIME_OFF_FAIL:
      return fetchTimeOffsFail(state, action);
    case actionTypes.FETCH_ALL_TIME_OFF_START:
      return fetchAllTimeOffsStart(state);
    case actionTypes.FETCH_ALL_TIME_OFF_SUCCESS:
      return fetchAllTimeOffsSuccess(state, action);
    case actionTypes.FETCH_ALL_TIME_OFF_FAIL:
      return fetchAllTimeOffsFail(state, action);
    case actionTypes.CREATE_TIME_OFF_START:
      return createTimeOffStart(state);
    case actionTypes.CREATE_TIME_OFF_SUCCESS:
      return createTimeOffSuccess(state, action);
    case actionTypes.CREATE_TIME_OFF_FAIL:
      return createTimeOffFail(state, action);
    case actionTypes.UPDATE_TIME_OFF_START:
      return updateTimeOffStart(state);
    case actionTypes.UPDATE_TIME_OFF_SUCCESS:
      return updateTimeOffSuccess(state);
    case actionTypes.UPDATE_TIME_OFF_FAIL:
      return updateTimeOffFail(state, action);
    case actionTypes.DELETE_TIME_OFF_START:
      return deleteTimeOffStart(state);
    case actionTypes.DELETE_TIME_OFF_SUCCESS:
      return deleteTimeOffSuccess(state);
    case actionTypes.DELETE_TIME_OFF_FAIL:
      return deleteTimeOffFail(state, action);
    case actionTypes.RESET_PARTIALLY_TIME_OFF_STORE:
      return resetPartiallyTimeOffStore(state);
    case actionTypes.RESET_TIME_OFF_STORE:
      return resetTimeOffStore();
    case actionTypes.LOGOUT:
      return completelyResetTimeOffStore();
    default:
      return state;
  }
};

export default reducer;
