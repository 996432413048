export const FETCH_PAYMENTS_START = 'FETCH_PAYMENTS_START';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAIL = 'FETCH_PAYMENTS_FAIL';

export const FETCH_CHILD_PAYMENTS_START = 'FETCH_CHILD_PAYMENTS_START';
export const FETCH_CHILD_PAYMENTS_SUCCESS = 'FETCH_CHILD_PAYMENTS_SUCCESS';
export const FETCH_CHILD_PAYMENTS_FAIL = 'FETCH_CHILD_PAYMENTS_SUCCESS';

export const FETCH_PARENT_PAYMENTS_START = 'FETCH_PARENT_PAYMENTS_START';
export const FETCH_PARENT_PAYMENTS_SUCCESS = 'FETCH_PARENT_PAYMENTS_SUCCESS';
export const FETCH_PARENT_PAYMENTS_FAIL = 'FETCH_PARENT_PAYMENTS_FAIL';

export const FETCH_PAYMENT_STATUS_START = 'FETCH_PAYMENT_STATUS_START';
export const FETCH_PAYMENT_STATUS_SUCCESS = 'FETCH_PAYMENT_STATUS_SUCCESS';
export const FETCH_PAYMENT_STATUS_FAIL = 'FETCH_PAYMENT_STATUS_FAIL';

export const DELETE_PAYMENT_START = 'DELETE_PAYMENT_START';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAIL = 'DELETE_PAYMENT_FAIL';

export const EXPORT_PAYMENTS_START = 'EXPORT_PAYMENTS_START';
export const EXPORT_PAYMENTS_SUCCESS = 'EXPORT_PAYMENTS_SUCCESS';
export const EXPORT_PAYMENTS_FAIL = 'EXPORT_PAYMENTS_FAIL';

export const UPDATE_CHILD_PAYMENT_LIST = 'UPDATE_CHILD_PAYMENT_LIST';

export const RESET_EXPORT_PAYMENTS = 'RESET_EXPORT_PAYMENTS';

export const RESET_PAYMENT_STORE = 'RESET_PAYMENT_STORE';

export const LOGOUT = 'LOGOUT';

export type PaymentActionTypes =
  | typeof FETCH_PAYMENTS_START
  | typeof FETCH_PAYMENTS_SUCCESS
  | typeof FETCH_PAYMENTS_FAIL
  | typeof FETCH_CHILD_PAYMENTS_START
  | typeof FETCH_CHILD_PAYMENTS_SUCCESS
  | typeof FETCH_CHILD_PAYMENTS_FAIL
  | typeof FETCH_PARENT_PAYMENTS_START
  | typeof FETCH_PARENT_PAYMENTS_SUCCESS
  | typeof FETCH_PARENT_PAYMENTS_FAIL
  | typeof FETCH_PAYMENT_STATUS_START
  | typeof FETCH_PAYMENT_STATUS_SUCCESS
  | typeof FETCH_PAYMENT_STATUS_FAIL
  | typeof EXPORT_PAYMENTS_START
  | typeof EXPORT_PAYMENTS_SUCCESS
  | typeof EXPORT_PAYMENTS_FAIL
  | typeof DELETE_PAYMENT_START
  | typeof DELETE_PAYMENT_SUCCESS
  | typeof DELETE_PAYMENT_FAIL
  | typeof UPDATE_CHILD_PAYMENT_LIST
  | typeof RESET_EXPORT_PAYMENTS
  | typeof RESET_PAYMENT_STORE
  | typeof LOGOUT;
