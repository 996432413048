export const FETCH_HEALTH_CHECK_START = 'FETCH_HEALTH_CHECK_START';
export const FETCH_HEALTH_CHECK_SUCCESS = 'FETCH_HEALTH_CHECK_SUCCESS';
export const FETCH_HEALTH_CHECK_FAIL = 'FETCH_HEALTH_CHECK_FAIL';

export const CREATE_HEALTH_CHECK_START = 'CREATE_HEALTH_CHECK_START';
export const CREATE_HEALTH_CHECK_SUCCESS = 'CREATE_HEALTH_CHECK_SUCCESS';
export const CREATE_HEALTH_CHECK_FAIL = 'CREATE_HEALTH_CHECK_FAIL';

export const UPDATE_HEALTH_CHECK_START = 'UPDATE_HEALTH_CHECK_START';
export const UPDATE_HEALTH_CHECK_SUCCESS = 'UPDATE_HEALTH_CHECK_SUCCESS';
export const UPDATE_HEALTH_CHECK_FAIL = 'UPDATE_HEALTH_CHECK_FAIL';

export const DELETE_HEALTH_CHECK_START = 'DELETE_HEALTH_CHECK_START';
export const DELETE_HEALTH_CHECK_SUCCESS = 'DELETE_HEALTH_CHECK_SUCCESS';
export const DELETE_HEALTH_CHECK_FAIL = 'DELETE_HEALTH_CHECK_FAIL';

export const RESET_HEALTH_CHECK_STORE = 'RESET_HEALTH_CHECK_STORE';
export const RESET_PARTIALLY_HEALTH_CHECK_STORE =
  'RESET_PARTIALLY_HEALTH_CHECK_STORE';

export const LOGOUT = 'LOGOUT';

export type HealthCheckActionTypes =
  | typeof FETCH_HEALTH_CHECK_START
  | typeof FETCH_HEALTH_CHECK_SUCCESS
  | typeof FETCH_HEALTH_CHECK_FAIL
  | typeof CREATE_HEALTH_CHECK_START
  | typeof CREATE_HEALTH_CHECK_SUCCESS
  | typeof CREATE_HEALTH_CHECK_FAIL
  | typeof UPDATE_HEALTH_CHECK_START
  | typeof UPDATE_HEALTH_CHECK_SUCCESS
  | typeof UPDATE_HEALTH_CHECK_FAIL
  | typeof DELETE_HEALTH_CHECK_START
  | typeof DELETE_HEALTH_CHECK_SUCCESS
  | typeof DELETE_HEALTH_CHECK_FAIL
  | typeof RESET_HEALTH_CHECK_STORE
  | typeof RESET_PARTIALLY_HEALTH_CHECK_STORE
  | typeof LOGOUT;
