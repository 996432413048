export const routes = {
  homepage: '/',
  privacyPolicy: '/privacy-policy',
  login: '/login',
  registration: '/registration',
  forgotPassword: '/forgot-password',
  passwordReset: '/password-reset/:code',
  registrationConfirmation: '/registration-confirmation/:code',
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  users: {
    list: '/admin/users',
    create: '/admin/users/new',
    edit: '/admin/users/:id',
  },
  branches: {
    list: '/admin/branches',
    edit: '/admin/branches/:id',
  },
  workTimes: {
    list: '/admin/work-times',
    create: '/admin/work-times/new',
    edit: '/admin/work-times/:id',
    calendar: '/admin/work-time-calendar',
  },
  employees: {
    list: '/admin/employees',
    create: '/admin/employees/new',
    edit: '/admin/employees/:id',
  },
  children: {
    list: '/admin/children',
    create: '/admin/children/new',
    edit: '/admin/children/:id',
  },
  additionalService: {
    list: '/admin/additional-services',
    create: '/admin/additional-services/new',
    edit: '/admin/additional-services/:id',
  },
  attendancePackages: {
    list: '/admin/packages',
  },
  translations: '/admin/translations',
  attendances: '/admin/attendances',
  achievements: '/admin/achievements',
  payments: '/admin/payments',
  comingSoon: '/coming-soon',
  languages: '/admin/languages',
  subscribedTimes: 'admin/subscribed-times',
  timeOffs: '/admin/time-offs',
  parent: {
    children: {
      list: '/parent/children',
      create: '/parent/children/new',
      edit: '/parent/children/:id',
    },
    profile: '/parent/profile',
    achievements: '/parent/achievements',
    purchase: '/parent/purchase',
    payments: '/parent/payments',
    attendances: '/parent/attendances',
    wantedTimes: '/parent/wanted-times',
  },
};
